import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Modal } from 'common-front/src/designSystem/components/modal/modal';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { EventsEventFragment } from 'common/src/generated/types';
import * as React from 'react';
import { EventArchiveModal } from '../eventArchiveModal';
import { EventDuplicateModal } from '../eventDuplicateModal';

interface IEventRowDropdownProps {
    event: EventsEventFragment;

    reload(): void;
}

export const EventRowDropdown = (props: IEventRowDropdownProps) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const [isDuplicateOpen, setIsDuplicateOpen] = React.useState(false);
    const [isArchiveOpen, setIsArchiveOpen] = React.useState(false);

    return (
        <>
            <DropdownEllipsis>
                <ItemIcon
                    icon="clone"
                    onClick={(e) => {
                        closeOtherDropdowns(e.target);
                        setIsDuplicateOpen(true);
                    }}
                >
                    {translate('dupliquer_28256')}
                </ItemIcon>

                <ItemIcon
                    color="red"
                    icon="trash-can"
                    onClick={(e) => {
                        closeOtherDropdowns(e.target);
                        setIsArchiveOpen(true);
                    }}
                >
                    {translate('archiver_26131')}
                </ItemIcon>
            </DropdownEllipsis>

            <Modal.Root
                isOpen={isDuplicateOpen}
                onClose={() => {
                    setIsDuplicateOpen(false);
                }}
            >
                <Modal.Portal>
                    <EventDuplicateModal
                        event={props.event}
                        reload={props.reload}
                        onClose={() => {
                            setIsDuplicateOpen(false);
                        }}
                    />
                </Modal.Portal>
            </Modal.Root>

            {isArchiveOpen && (
                <EventArchiveModal
                    event={props.event}
                    organizationId={organizationId}
                    onClose={() => setIsArchiveOpen(false)}
                    onSuccess={props.reload}
                />
            )}
        </>
    );
};
