import { UpdateCell } from 'common-front/src/components/cells/updateCell';
import { usePositionsMassEditMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { MassAssignStrategy, PositionId, PositionProperty } from 'common/src/generated/types';
import * as React from 'react';

interface IPositionsUpdateCellProps<T> {
    cellCss?: any;
    initialValue: T;
    positionId: PositionId;
    property: PositionProperty;
    updatable: boolean;

    reload(): void;
    renderInput(value: T, setValue: (v: T) => void): React.ReactNode;
    renderValue: string | (() => React.ReactNode);
}

export const PositionsUpdateCell = <T extends {}>(props: IPositionsUpdateCellProps<T>) => {
    const {
        params: { eventId }
    } = useHeavent();
    const { mutate } = usePositionsMassEditMutation();

    return (
        <UpdateCell
            cellCss={props.cellCss}
            initialValue={props.initialValue}
            mutate={(value) =>
                mutate({
                    eventId,
                    massEdit: {
                        reset: false,
                        selecteds: { ids: [props.positionId] },
                        slug: props.property,
                        strategy: MassAssignStrategy.Replace,
                        value
                    }
                })
            }
            reload={props.reload}
            renderInput={props.renderInput}
            renderValue={props.renderValue}
            updatable={props.updatable}
        />
    );
};
