import { injectable } from 'inversify';
import { CommonEnvVars } from '../envVars';
import {
    CustomBadge,
    CustomFieldWithConditionFragment,
    Event as EventVo,
    Organization as OrganizationVo,
    UsersInfo as UsersInfoVo,
    VolunteerRegistrationEmailRendererFragment
} from '../generated/types';
import { isNonEmptyArray } from '../util/array';
import { Emptyable } from '../util/emptyable';
import { flattenObject } from '../util/object';
import { isNonEmptyString } from '../util/string';
import { getDocumentHref } from '../util/url';
import { FieldService } from '../vo/field';
import { getName } from '../vo/userInfo';

type Organization = Pick<OrganizationVo, 'id' | 'name' | 'exportDatetimeFormat'>;
type Event = Pick<EventVo, 'id' | 'name' | 'startAt' | 'endAt'>;
type UsersInfo = Pick<UsersInfoVo, 'id' | 'email' | 'fields' | 'links' | 'registrationDate'>;

/* eslint-disable-next-line no-useless-escape */
const VARIABLE_REGEX = /(\{([\w\.]+)\})/g;

@injectable()
export class TemplateService {
    constructor(private fieldService: FieldService) {}

    renderText(
        text: string,
        userInfo: UsersInfo,
        organization: Emptyable<Organization>,
        event: Emptyable<Event>,
        customsFields: CustomFieldWithConditionFragment[],
        volunteerRegistration: Emptyable<VolunteerRegistrationEmailRendererFragment>,
        extraInfos: any = {}
    ): string {
        const fields = this.getFields(userInfo, customsFields);
        const userAssignmentsPath = userInfo.links
            ? getDocumentHref(userInfo.links.userAssignmentsLink, 'pdf')
            : '';
        const userPlanningPath = userInfo.links
            ? getDocumentHref(userInfo.links.userPlanningLink, 'pdf')
            : '';
        const userPlanningListPath = userInfo.links
            ? getDocumentHref(userInfo.links.userPlanningListLink, 'pdf')
            : '';
        const userPlanningDaysPath = userInfo.links
            ? getDocumentHref(userInfo.links.userPlanningDaysLink, 'pdf')
            : '';
        const documents = userInfo.links
            ? {
                  assignments: userAssignmentsPath,
                  badge: getDocumentHref(userInfo.links.userBadgeLink, 'pdf'),
                  certificate: getDocumentHref(userInfo.links.userCertificateLink, 'pdf'),
                  planning: userPlanningPath,
                  planningList: userPlanningListPath,
                  planningDays: userPlanningDaysPath,
                  ...Object.fromEntries(
                      Object.entries(userInfo.links.userCustomBadgeLinks).map(
                          ([customBadge, link]) => [
                              customBadge.toLowerCase(),
                              getDocumentHref(link as string, 'pdf')
                          ]
                      )
                  ),
                  ...Object.fromEntries(
                      Object.entries(userInfo.links.userCustomDocumentLinks).map(([slug, link]) => [
                          slug,
                          getDocumentHref(link as string, 'pdf')
                      ])
                  )
              }
            : {};
        const ticketId =
            volunteerRegistration?.weezeventParticipantInfo?.idBarcode ??
            volunteerRegistration?.ticketId ??
            '';
        const firstName =
            (userInfo.fields.isExpanded
                ? userInfo.fields?.firstName?.value
                : userInfo.fields.firstName) || '';
        const lastName =
            (userInfo.fields.isExpanded
                ? userInfo.fields?.lastName?.value
                : userInfo.fields.lastName) || '';
        const qrcode = `<div style="height: 100px; margin: auto; text-align: center; width: 100px;">
            <img
                src="${CommonEnvVars.HEAVENT_API_URL}/barcode/qr?text=${encodeURIComponent(ticketId)}&color=000000&height=100&width=100"
                height="100%"
                width="100%"
            />
        </div>`;
        const variables = {
            organization: organization || undefined,
            event: event || undefined,
            user: {
                id: userInfo.id,
                email: userInfo.email,
                ...fields,
                firstName,
                lastName,
                ticketId,
                qrcode,
                name: getName({ firstName, lastName }),
                registrationDate:
                    userInfo.registrationDate?.toFormat(
                        organization?.exportDatetimeFormat ?? 'yyyy-MM-dd HH:mm'
                    ) ?? '',
                assignmentSheet: event ? userAssignmentsPath : '',
                documents,
                delegation: {
                    name: volunteerRegistration?.delegation?.name ?? '',
                    leader: {
                        firstName: volunteerRegistration?.delegation?.leaders?.[0]?.firstName ?? '',
                        lastName: volunteerRegistration?.delegation?.leaders?.[0]?.lastName ?? '',
                        phone:
                            volunteerRegistration?.delegation?.leaders?.[0]?.phone
                                ?.internationalFormat ?? ''
                    }
                },
                weezevent: {
                    barcode: ticketId,
                    qrcode,
                    identificationNumber:
                        volunteerRegistration?.weezeventParticipantInfo?.identificationNumber ?? ''
                }
            },
            ...extraInfos
        };

        const variablesFlattened = flattenObject(variables);

        return Object.entries(variablesFlattened)
            .reduce(
                (currentText: string, [key, value]: [string, any]) =>
                    currentText.replaceAll(`{${key}}`, value),
                text
            )
            .replace(VARIABLE_REGEX, (_match: string, _group1: string, group2: string) => {
                const [_u, _d, _n, date] = group2.split('.');
                const query: any = {};

                if (date?.length === 8) {
                    query.date = date;
                } else if (date?.length === 16) {
                    query.date = date.substring(0, 8);
                    query.endDate = date.substring(8);
                }

                if (group2.includes('planningDays') && userInfo.links) {
                    return getDocumentHref(userInfo.links.userPlanningDaysLink, 'pdf', query);
                } else if (group2.includes('planningList') && userInfo.links) {
                    return getDocumentHref(userInfo.links.userPlanningListLink, 'pdf', query);
                } else if (group2.includes('planning') && userInfo.links) {
                    return getDocumentHref(userInfo.links.userPlanningLink, 'pdf', query);
                } else if (group2.includes('assignments') && userInfo.links) {
                    return getDocumentHref(userInfo.links.userAssignmentsLink, 'pdf', query);
                } else if (
                    group2.includes('paris20kmassignment') &&
                    userInfo.links?.userCustomBadgeLinks[CustomBadge.Paris20kmassignment]
                ) {
                    return getDocumentHref(
                        userInfo.links!.userCustomBadgeLinks[CustomBadge.Paris20kmassignment],
                        'pdf',
                        query
                    );
                } else {
                    return '';
                }
            });
    }

    private getFields(userInfo: UsersInfo, customsFields: CustomFieldWithConditionFragment[]) {
        if (userInfo.fields.isExpanded) {
            return Object.fromEntries(
                Object.entries(userInfo.fields).map(
                    ([slug, { internationalFormat, name, value }]: any) => {
                        const finalValue = isNonEmptyString(internationalFormat)
                            ? internationalFormat
                            : isNonEmptyString(name)
                              ? name
                              : isNonEmptyArray(value)
                                ? value.join(', ')
                                : isNonEmptyString(value)
                                  ? value
                                  : '';

                        return [slug, finalValue];
                    }
                )
            );
        } else {
            return Object.fromEntries(
                customsFields.map((customField) => [
                    customField.slug,
                    this.fieldService.getValueString(customField, userInfo.fields)
                ])
            );
        }
    }
}
