import { UsersInfosMassEditModal } from 'common-front/src/components/massEdit/massEditModal';
import { Button } from 'common-front/src/designSystem/components/button';
import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Title } from 'common-front/src/designSystem/components/dropdown/title';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useUsersInfosMassEditMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { IUseMassActionsResult } from 'common-front/src/hooks/useMassActions';
import { Feature, UsersInfoId } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { Predicates } from 'common/src/vo/segments/predicate';
import * as React from 'react';
import {
    useUsersActionsQuery,
    useUsersInfosMassDeleteMutation
} from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { MassCampaignSendModal } from '../../volunteers/massCampaignSend/massCampaignSendModal';
import { MassDelegationAddModal } from '../../volunteers/massDelegationAdd/massDelegationAddModal';
import { MassFormAddModal } from '../../volunteers/massFormAdd/massFormAddModal';
import { MassFormRemoveModal } from '../../volunteers/massFormRemove/massFormRemoveModal';
import { MassAddEventModal } from './massAddEvent/massAddEventModal';
import { MergeMembersModal } from './mergeMembers/mergeMembersModal';

interface IUsersActionsProps {
    massActions: IUseMassActionsResult<UsersInfoId>;
    predicates: Emptyable<Predicates>;
    showOnlyMassEdit: boolean;
    totalNumberOfMembers: number;

    onMassEditSuccess(): void;
}

export const UsersActions = (props: IUsersActionsProps) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const { mutate } = useUsersInfosMassEditMutation();
    const { mutate: usersInfosMassDelete } = useUsersInfosMassDeleteMutation();
    const { hasFeature } = useOrganizationContext();
    /*
    const { massActions, onMassEditSuccess, organizationId, predicates, segment } =
        useSegmentsContext<UsersInfoId, UsersInfosSortAttributes>();
     */
    const { data } = useUsersActionsQuery({ organizationId });
    const [isMassEditVisible, setIsMassEditVisible] = React.useState(false);
    const [isMassAddEventVisible, setIsMassAddEventVisible] = React.useState(false);
    const [isMergeModalOpen, setIsMergeModalOpen] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [isMassDelegationAddVisible, setIsMassDelegationAddVisible] = React.useState(false);
    const [isMassFormAddVisible, setIsMassFormAddVisible] = React.useState(false);
    const [isMassFormRemoveVisible, setIsMassFormRemoveVisible] = React.useState(false);
    const [isMassCampaignSendVisible, setIsMassCampaignSendVisible] = React.useState(false);
    const selectedsInput = props.massActions.getSelectedsInput(props.predicates, null);
    const finalNumberOfSelected = props.massActions.isAllSelected
        ? props.totalNumberOfMembers
        : props.massActions.numberOfSelected;

    return (
        <>
            <Button
                onClick={() => {
                    setIsMassEditVisible(true);
                }}
            >
                {translate('_diter_un_champ_64621')}
            </Button>

            {!props.showOnlyMassEdit && (
                <Dropdown>
                    <Trigger>
                        <Button color="white" leftIcon="ellipsis-vertical" />
                    </Trigger>

                    <Menu placement="bottom-end">
                        <Title>{translate('gestion_des_mem_51660')}</Title>

                        <ItemIcon
                            icon="plus"
                            isDisabled={!data.organization?.hasEvents}
                            onClick={() => {
                                setIsMassAddEventVisible(true);
                            }}
                        >
                            {translate('ajouter_1_membr_25541', finalNumberOfSelected)}
                        </ItemIcon>

                        <ItemIcon
                            icon="merge"
                            onClick={() => {
                                setIsMergeModalOpen(true);
                            }}
                        >
                            {translate('fusionner_les_m_37150')}
                        </ItemIcon>

                        <Separator color="gray100" direction="horizontal" />

                        <Title>{translate('accr_ditations_39450')}</Title>

                        {hasFeature(Feature.Delegation) && (
                            <ItemIcon
                                icon="people-group"
                                isDisabled={!data.organization?.hasDelegations}
                                onClick={() => {
                                    setIsMassDelegationAddVisible(true);
                                }}
                            >
                                {data.organization?.hasDelegations
                                    ? translate('ajouter_1_mem_86218', finalNumberOfSelected)
                                    : translate('cette_organisat_48937')}
                            </ItemIcon>
                        )}

                        <Separator color="gray100" direction="horizontal" />

                        <Title>{translate('communication_05277')}</Title>

                        <ItemIcon
                            icon="file-lines"
                            isDisabled={!data.organization?.hasForms}
                            onClick={() => {
                                setIsMassFormAddVisible(true);
                            }}
                        >
                            {translate('ajouter_1_mem_52947', finalNumberOfSelected)}
                        </ItemIcon>

                        <ItemIcon
                            icon="file-lines"
                            isDisabled={!data.organization?.hasForms}
                            onClick={() => {
                                setIsMassFormRemoveVisible(true);
                            }}
                        >
                            {translate('marquer_un_form_10633')}
                        </ItemIcon>

                        <ItemIcon
                            icon="paper-plane"
                            onClick={() => {
                                setIsMassCampaignSendVisible(true);
                            }}
                        >
                            {translate('envoyer_une_cam_94630')}
                        </ItemIcon>

                        <Separator color="gray100" direction="horizontal" />

                        <ItemIcon
                            color="red"
                            icon="trash-can"
                            onClick={() => {
                                setIsDeleteModalOpen(true);
                            }}
                        >
                            {translate('supprimer_43083')}
                        </ItemIcon>
                    </Menu>
                </Dropdown>
            )}

            {isMassEditVisible && (
                <UsersInfosMassEditModal
                    mutate={(massEdit) =>
                        mutate({
                            organizationId,
                            massEdit
                        })
                    }
                    organizationId={organizationId}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassEditVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}

            {isMassAddEventVisible && (
                <MassAddEventModal
                    numberOfSelected={finalNumberOfSelected}
                    organizationId={organizationId}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassAddEventVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}

            {isMergeModalOpen && (
                <MergeMembersModal
                    numbersOfSelected={finalNumberOfSelected}
                    organizationId={organizationId}
                    usersInfosIds={props.massActions.selectedsIds}
                    onClose={() => {
                        setIsMergeModalOpen(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}

            {isDeleteModalOpen && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={[
                        translate('_tes_vous_s_r_d_65177'),
                        translate('cette_action_es_45424')
                    ]}
                    text={translate('suppression_de_57689', finalNumberOfSelected)}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                    }}
                    onDelete={async () => {
                        await usersInfosMassDelete({
                            organizationId,
                            selecteds: selectedsInput
                        });

                        props.onMassEditSuccess();
                    }}
                />
            )}

            {isMassFormAddVisible && (
                <MassFormAddModal
                    eventId={null}
                    organizationId={organizationId}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassFormAddVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}

            {isMassFormRemoveVisible && (
                <MassFormRemoveModal
                    eventId={null!}
                    organizationId={organizationId}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassFormRemoveVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}

            {isMassDelegationAddVisible && (
                <MassDelegationAddModal
                    eventId={null}
                    organizationId={organizationId}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassDelegationAddVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}

            {isMassCampaignSendVisible && (
                <MassCampaignSendModal
                    eventId={null}
                    organizationId={organizationId}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassCampaignSendVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}
        </>
    );
};
