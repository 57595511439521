import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentsGrid } from 'common-front/src/segments/segmentsGrid';
import { AccreditationsSegmentsQuery, SegmentId, SegmentType } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import { AccreditationsRendering } from 'common/src/vo/rendering';
import { AccreditationsSegmentsService } from 'common/src/vo/segments/accreditationsSegmentsService';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { AccreditationsContent } from './accreditationsContent';
import { AccreditationsContextProvider } from './accreditationsContext';

interface IAccreditationsProps {
    event: AccreditationsSegmentsQuery['event'];
    organization: AccreditationsSegmentsQuery['organization'];

    reload(): void;
}

export const Accreditations = (props: IAccreditationsProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, segmentId }
    } = useHeavent();
    const { isEventAdmin, localEvent, updateLocalEvent } = useEventContext();
    const segmentService = useService(AccreditationsSegmentsService);
    const customFields = React.useMemo(
        () => props.organization?.customFields.nodes ?? [],
        [props.organization]
    );
    const filters = React.useMemo(
        () => segmentService.getAccreditationsFilters(props.event, customFields),
        [customFields, props.event]
    );
    const possibleColumns = React.useMemo(
        () =>
            segmentService.getAccreditationsPossibleColumns(
                props.event.isWeezeventConnected,
                customFields
            ),
        [customFields, props.event]
    );

    return (
        <AccreditationsContextProvider eventId={eventId} organizationId={organizationId}>
            <SegmentsGrid
                eventId={eventId}
                filters={filters}
                filtersButtonText={translate('filtrer_les_acc_57840')}
                filtersSubtitle={translate('appliquer_des_f_18351')}
                getSegmentPath={(id: SegmentId) =>
                    HeaventPaths.ACCREDITATIONS_SEGMENT(organizationId, eventId, id)
                }
                hideSegments={props.event.numberOfAccreditations === 0}
                initialLimit={localEvent?.accreditationsLimit ?? DEFAULT_PAGINATION_LIMIT}
                initialSegmentsOpen={localEvent?.areAccreditationsSegmentsOpen ?? true}
                isAdmin={isEventAdmin}
                organizationId={organizationId}
                possibleColumns={possibleColumns}
                possibleRenderings={[
                    AccreditationsRendering.List,
                    AccreditationsRendering.SlotsList
                ]}
                reload={props.reload}
                segmentId={segmentId}
                segmentType={SegmentType.Accreditations}
                segmentsFolders={props.event.segmentsFolders}
                setLimit={(accreditationsLimit) => {
                    updateLocalEvent({ accreditationsLimit });
                }}
                onSegmentClick={(selectedSegmentId) => {
                    updateLocalEvent({ accreditationsSegmentId: selectedSegmentId });
                }}
                onSegmentDelete={async () => {
                    await updateLocalEvent({ accreditationsSegmentId: null });

                    history.replace(
                        AccreditationsPaths.ACCREDITATIONS({ organizationId, eventId })
                    );
                }}
                onSegmentsToggle={async (areSegmentsOpen) => {
                    await updateLocalEvent({ areAccreditationsSegmentsOpen: areSegmentsOpen });
                }}
            >
                <AccreditationsContent
                    customFields={customFields}
                    numberOfAccreditations={props.event.numberOfAccreditations}
                    weezeventOrganizationId={props.organization.weezeventOrganizationId}
                />
            </SegmentsGrid>
        </AccreditationsContextProvider>
    );
};
