import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { FilterType } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { FilterPredicate } from 'common/src/vo/segment';
import { Predicate } from 'common/src/vo/segments/predicate';
import * as React from 'react';
import { Loader } from '../../components/loader/loader';
import { AddCampaignFilter } from './addCampaignFilter';
import { AddCheckboxFilter } from './addCheckboxFilter';
import { AddConditionFilter } from './addConditionFilter';
import { AddDateFilter } from './addDateFilter';
import { AddDatetimeFilter } from './addDatetimeFilter';
import { AddDateTimeRangeFilter } from './addDateTimeRangeFilter';
import { AddFileFilter } from './addFileFilter';
import { AddFormsInsertedAtFilter } from './addFormsInsertedAtFilter';
import { AddIsAdminFilter } from './addIsAdminFilter';
import { AddIsLeaderFilter } from './addIsLeaderFilter';
import { AddIsTeamFilter } from './addIsTeamFilter';
import { AddNumberFilter } from './addNumberFilter';
import { AddPrivacyFilter } from './addPrivacyFilter';
import { AddSelectFilter } from './addSelectFilter';
import { AddTextFilter } from './addTextFilter';
import { AddWishedPositionsFilter } from './addWishedPositionsFilter';
import { useFilterLoader } from './data/useFilterLoader';

interface IAddFilterProps {
    filterPredicate: FilterPredicate;

    onBack(): void;
    onAdd(predicate: Predicate): void;
}

export const AddFilter = (props: IAddFilterProps) => {
    const translate = useTranslate();
    const slug = props.filterPredicate.filter.slug;
    const fieldType = props.filterPredicate.filter.fieldType;
    const isLoading = useFilterLoader(props.filterPredicate.filter);

    return (
        <>
            <Flex
                align="center"
                css={{
                    color: '$gray500',
                    cursor: 'pointer',
                    fontSize: '$textXs',
                    fontWeight: '$semiBold'
                }}
                gap="3"
                onClick={props.onBack}
            >
                <i className="fa-regular fa-arrow-left" />
                {translate('retour_la_lis_63860')}
            </Flex>

            <Spacer height="4" />

            <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                {props.filterPredicate.filter.name}
            </Box>

            <Spacer height="3" />

            {isLoading ? (
                <Loader />
            ) : slug === 'condition' ? (
                <AddConditionFilter filterPredicate={props.filterPredicate} onAdd={props.onAdd} />
            ) : slug === 'isLeader' ||
              slug === 'isPositionLeader' ||
              slug === 'isPositionCategoryLeader' ||
              slug === 'isDelegationLeader' ? (
                <AddIsLeaderFilter filterPredicate={props.filterPredicate} onAdd={props.onAdd} />
            ) : slug === 'isAdmin' ? (
                <AddIsAdminFilter filterPredicate={props.filterPredicate} onAdd={props.onAdd} />
            ) : slug === 'privacy' ? (
                <AddPrivacyFilter filterPredicate={props.filterPredicate} onAdd={props.onAdd} />
            ) : slug === 'isTeam' ? (
                <AddIsTeamFilter filterPredicate={props.filterPredicate} onAdd={props.onAdd} />
            ) : slug === 'wishedPositions' || slug === 'wishedPositionsCategories' ? (
                <AddWishedPositionsFilter
                    filterPredicate={props.filterPredicate}
                    onAdd={props.onAdd}
                />
            ) : slug === 'formsInsertedAt' ? (
                <AddFormsInsertedAtFilter
                    filterPredicate={props.filterPredicate}
                    onAdd={props.onAdd}
                />
            ) : [FilterType.Text, FilterType.Textarea].includes(fieldType) ? (
                <AddTextFilter
                    filterPredicate={props.filterPredicate}
                    showIsValid={false}
                    onAdd={props.onAdd}
                />
            ) : [
                  FilterType.Country,
                  FilterType.Language,
                  FilterType.Nationality,
                  FilterType.Select,
                  FilterType.Sex
              ].includes(fieldType) ? (
                <AddSelectFilter filterPredicate={props.filterPredicate} onAdd={props.onAdd} />
            ) : [FilterType.Checkbox, FilterType.Validation].includes(fieldType) ? (
                <AddCheckboxFilter filterPredicate={props.filterPredicate} onAdd={props.onAdd} />
            ) : fieldType === FilterType.Phone ? (
                <AddTextFilter
                    filterPredicate={props.filterPredicate}
                    showIsValid={true}
                    onAdd={props.onAdd}
                />
            ) : fieldType === FilterType.Number ? (
                <AddNumberFilter filterPredicate={props.filterPredicate} onAdd={props.onAdd} />
            ) : fieldType === FilterType.DateTimeRange ? (
                <AddDateTimeRangeFilter
                    filterPredicate={props.filterPredicate}
                    onAdd={props.onAdd}
                />
            ) : fieldType === FilterType.Date ? (
                <AddDateFilter filterPredicate={props.filterPredicate} onAdd={props.onAdd} />
            ) : fieldType === FilterType.Datetime ? (
                <AddDatetimeFilter filterPredicate={props.filterPredicate} onAdd={props.onAdd} />
            ) : fieldType === FilterType.File ? (
                <AddFileFilter filterPredicate={props.filterPredicate} onAdd={props.onAdd} />
            ) : fieldType === FilterType.Campaign ? (
                <AddCampaignFilter filterPredicate={props.filterPredicate} onAdd={props.onAdd} />
            ) : (
                <div>TODO</div>
            )}
        </>
    );
};
