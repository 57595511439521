import { Page } from 'common-front/src/components/page/page';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SearchRow } from 'common-front/src/segments/searchRow';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { SegmentTableHeader } from 'common-front/src/segments/segmentTableHeader';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { CreateCustomField, UpdateCustomField } from '../create/createUpdateCustomField';
import { CustomFieldsActions } from './customFieldsActions';
import { CustomFieldsHeader } from './customFieldsHeader';
import { CustomFieldsList } from './customFieldsList';
import { CustomFieldUsagePanel } from './customFieldUsage/customFieldUsagePanel';

export const CustomFieldsContent = () => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const { reloadKey, segmentId, setReloadKey } = useSegmentsContext();

    return (
        <>
            <CustomFieldsHeader />

            <Page.Content>
                <SegmentTableHeader
                    searchRow={
                        <SearchRow
                            actions={<CustomFieldsActions />}
                            searchPlaceholder={translate('rechercher_un_c_49598')}
                            showEditColumns={true}
                            showEditMode={true}
                            showSearchColumns={false}
                        />
                    }
                />

                <CustomFieldsList />

                <Switch>
                    <Route
                        path={HeaventPaths.CUSTOM_FIELD_USAGE(
                            ':organizationId',
                            ':segmentId',
                            ':customFieldId'
                        )}
                    >
                        <CustomFieldUsagePanel />
                    </Route>

                    <Route path={HeaventPaths.CREATE_CUSTOM_FIELD(':organizationId', ':segmentId')}>
                        <CreateCustomField
                            organizationId={organizationId}
                            onClose={() => {
                                history.goBack(
                                    HeaventPaths.CUSTOM_FIELDS_SEGMENT(organizationId, segmentId)
                                );
                            }}
                            onSuccess={() => {
                                setReloadKey(reloadKey + 1);
                            }}
                        />
                    </Route>

                    <Route
                        path={HeaventPaths.EDIT_CUSTOM_FIELD(
                            ':organizationId',
                            ':segmentId',
                            ':customFieldId'
                        )}
                    >
                        <UpdateCustomField
                            onClose={() => {
                                history.goBack(
                                    HeaventPaths.CUSTOM_FIELDS_SEGMENT(organizationId, segmentId)
                                );
                            }}
                            onSuccess={() => {
                                setReloadKey(reloadKey + 1);
                            }}
                        />
                    </Route>
                </Switch>
            </Page.Content>
        </>
    );
};
