import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentHeader } from 'common-front/src/segments/segmentHeader';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';

export const CommunityDelegationsHeader = () => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();

    return (
        <SegmentHeader>
            <Button
                onClick={() => {
                    history.push(HeaventPaths.COMMUNITY_CREATE_DELEGATION(organizationId));
                }}
            >
                {translate('cr_er_une_d_l_g_23035')}
            </Button>
        </SegmentHeader>
    );
};
