import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Item } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu as DropdownMenu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Images } from 'common-front/src/util/assets';
import { signOut } from 'common-front/src/util/aws/cognito';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex, SectioningElement } from 'common/src/designSystem/components/flex';
import { Link } from 'common/src/designSystem/components/link';
import { theme } from 'common/src/designSystem/components/stitches';
import { CommonEnvVars } from 'common/src/envVars';
import { HeaventEnv } from 'common/src/heaventEnv';
import { isNonEmptyArray } from 'common/src/util/array';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import { CommunityPaths } from 'common/src/util/paths/communityPaths';
import { EventsPaths } from 'common/src/util/paths/eventsPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { canAccessBackoffice } from 'common/src/vo/backoffice';
import { groupBy, sortBy } from 'lodash-es';
import * as React from 'react';
import LogoRecrewteer from 'svgComponents/logo/logoRecrewteer.svg';
import LogoWeezcrew from 'svgComponents/logo/logoWeezcrew.svg';
import { useOrganizationsQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../organizationContext';
import { EventsMenu, getMenuItemStyles } from './eventsMenu';
import { OrganizationMenuWeezevent } from './organizationMenuWeezevent';

const getLogo = () => {
    switch (CommonEnvVars.HEAVENT_ENV) {
        case HeaventEnv.Amicale:
            return <img alt="Logo" height="24px" src={Images.AmicaleLogo} />;
        case HeaventEnv.Mso:
            return <img alt="Logo" height="24px" src={Images.MsoLogo2} />;
        case HeaventEnv.Recrewteer:
            return <LogoRecrewteer />;
        case HeaventEnv.Weezcrew:
            return <LogoWeezcrew />;
        default:
            return assertUnreachable(CommonEnvVars.HEAVENT_ENV);
    }
};

const getStyles = () => {
    switch (CommonEnvVars.HEAVENT_ENV) {
        case HeaventEnv.Amicale:
            return { background: 'white', boxShadow: theme.shadows.sm.value };
        case HeaventEnv.Mso:
            return { background: theme.colors.cyan.value, boxShadow: theme.shadows.sm.value };
        case HeaventEnv.Recrewteer:
        case HeaventEnv.Weezcrew:
            return { background: theme.colors.blueGray900.value };
        default:
            return assertUnreachable(CommonEnvVars.HEAVENT_ENV);
    }
};

interface IMenuItemProps {
    to: string;
    children: React.ReactNode;

    isActive?(pathname: string): boolean;
    onClick?(): Promise<any>;
}

const MenuItem = ({ to, children, isActive: _isActive, onClick }: IMenuItemProps) => {
    const { pathname } = useLocation();
    const isActive = _isActive ? _isActive(pathname) : to === pathname;

    return (
        <Flex
            aria-selected={isActive}
            css={{
                ...getMenuItemStyles(isActive),
                cursor: 'pointer',
                px: '$4',
                blockSize: '100%',
                alignItems: 'center'
            }}
            height={1}
            role="menuitem"
        >
            <Link height={1} to={to} width={1} onClick={onClick}>
                {children}
            </Link>
        </Flex>
    );
};

export const OrganizationMenu = React.memo(function OrganizationMenu() {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const { isOrganizationAdmin, organization, user, updateLocalOrganization } =
        useOrganizationContext();
    const { data } = useOrganizationsQuery({});
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const organizations = React.useMemo(() => {
        const organizationIdToRoles = groupBy(data.user?.roles ?? [], (r) => r.organizationId);

        return sortBy(
            (data.organizations || []).filter((o) => isNonEmptyArray(organizationIdToRoles[o.id])),
            (o) => o.name
        );
    }, [data.organizations, data.user]);

    return (
        <Flex
            align="center"
            css={{
                ...getStyles(),
                color: 'white',
                cursor: 'pointer',
                position: 'relative',
                px: '$6'
            }}
            height={1}
            justify="between"
            width={1}
        >
            <Flex
                align="center"
                aria-label={translate('page_d_accueil_12532')}
                css={
                    CommonEnvVars.HEAVENT_ENV === HeaventEnv.Recrewteer
                        ? {
                              '& svg': { height: '50%' }
                          }
                        : {}
                }
                height={1}
                width={300}
            >
                <Link height={1} to={EventsPaths.EVENTS({ organizationId })}>
                    {getLogo()}
                </Link>
            </Flex>

            <Flex
                aria-label={translate('menu_de_l_organ_58508')}
                as={SectioningElement.Nav}
                height={1}
            >
                <Flex align="center" justify="center" role="menubar">
                    <EventsMenu />

                    {isOrganizationAdmin && (
                        <MenuItem
                            isActive={(pathname) =>
                                pathname.startsWith(HeaventPaths.CUSTOM_FIELDS(organizationId))
                            }
                            to={HeaventPaths.CUSTOM_FIELDS(organizationId)}
                            onClick={async () =>
                                await updateLocalOrganization({ customFieldsSegmentId: null })
                            }
                        >
                            {translate('champs_de_formu_64126')}
                        </MenuItem>
                    )}

                    {isOrganizationAdmin && (
                        <MenuItem
                            isActive={(pathname) =>
                                pathname.startsWith(CommunityPaths.COMMUNITY({ organizationId }))
                            }
                            to={CommunityPaths.COMMUNITY_DASHBOARD({ organizationId })}
                        >
                            {translate('communaut_20896')}
                        </MenuItem>
                    )}

                    <MenuItem
                        isActive={(pathname) =>
                            pathname.startsWith(
                                HeaventPaths.ORGANIZATION_PARAMETERS(organizationId)
                            )
                        }
                        to={HeaventPaths.ORGANIZATION_PARAMETERS_PROFILE(organizationId)}
                    >
                        {translate('param_tres_78420')}
                    </MenuItem>
                </Flex>
            </Flex>

            <Flex align="center" gap="6" justify="end" width={300}>
                {typeof organization.weezeventOrganizationId === 'number' && (
                    <OrganizationMenuWeezevent
                        weezeventOrganizationId={organization.weezeventOrganizationId}
                    />
                )}

                <Dropdown
                    aria-label={translate('menu_utilisateu_40033')}
                    aria-orientation="vertical"
                    isOpen={isDropdownOpen}
                    onStateChange={setIsDropdownOpen}
                >
                    <Trigger>
                        <Flex
                            align="center"
                            aria-expanded={isDropdownOpen}
                            aria-label={translate('ouvrir_le_menu_47803')}
                            css={{
                                color: getMenuItemStyles(false).color,
                                cursor: 'default'
                            }}
                            gap="3"
                            role="button"
                            onClick={() => document.getElementById('user-menu')?.focus()}
                        >
                            <Avatar
                                email={user.email}
                                image={user.userInfo.picture?.url}
                                name={user.userInfo.name}
                                size={32}
                            />

                            <Box
                                css={{
                                    maxWidth: 'calc(100% - 66px)'
                                }}
                            >
                                <Box css={{ ellipsis: true }} fontWeight="semiBold">
                                    {user.userInfo.firstName || user.email}
                                </Box>
                                <Box css={{ ellipsis: true }} fontSize="textXs">
                                    {organization.name}
                                </Box>
                            </Box>

                            <div>
                                {isDropdownOpen ? (
                                    <i className="far fa-angle-up" />
                                ) : (
                                    <i className="far fa-angle-down" />
                                )}
                            </div>
                        </Flex>
                    </Trigger>

                    <DropdownMenu
                        aria-orientation="vertical"
                        id="user-menu"
                        maxHeight={500}
                        placement="bottom-end"
                        width={300}
                    >
                        <Item>
                            <Flex align="center" gap="3" width={1}>
                                <Avatar
                                    email={user.email}
                                    image={user.userInfo.picture?.url}
                                    name={user.userInfo.name}
                                    size={32}
                                />

                                <Box
                                    css={{
                                        width: 'calc(100% - 44px)'
                                    }}
                                >
                                    <Box css={{ ellipsis: true }} fontWeight="semiBold">
                                        {user.userInfo.name || user.email}
                                    </Box>
                                    {isNonEmptyString(user.userInfo.name) && (
                                        <Box color="gray500" css={{ ellipsis: true }}>
                                            {user.email}
                                        </Box>
                                    )}
                                </Box>
                            </Flex>
                        </Item>

                        <Separator color="gray100" direction="horizontal" />

                        <Flex as={SectioningElement.Nav} direction="column" role="menubar">
                            {organizations.map(({ id, name }) => (
                                <Item
                                    key={id}
                                    onClick={async () => {
                                        await updateLocalOrganization({ eventsSegmentId: null });
                                        // This needs to be `location.href` to fully clear the cached
                                        // `eventsSegmentId`.
                                        // TODO - is there a better/cleaner way to avoid this bug?
                                        location.href = EventsPaths.EVENTS({ organizationId: id });
                                        document.getElementById('main')?.focus();
                                    }}
                                >
                                    {name}
                                </Item>
                            ))}

                            <Separator color="gray100" direction="horizontal" />

                            {isOrganizationAdmin && (
                                <>
                                    <Item
                                        to={HeaventPaths.ORGANIZATION_PARAMETERS_PROFILE(
                                            organizationId
                                        )}
                                        onClick={() => {
                                            setIsDropdownOpen(false);
                                            document.getElementById('main')?.focus();
                                        }}
                                    >
                                        {translate('mon_profil_48224')}
                                    </Item>

                                    <Item
                                        to={HeaventPaths.ORGANIZATION_PARAMETERS_INFOS(
                                            organizationId
                                        )}
                                        onClick={() => {
                                            setIsDropdownOpen(false);
                                            document.getElementById('main')?.focus();
                                        }}
                                    >
                                        {translate('organisation_71863')}
                                    </Item>

                                    <Item
                                        to={HeaventPaths.ORGANIZATION_PARAMETERS_TEAM(
                                            organizationId
                                        )}
                                        onClick={() => {
                                            setIsDropdownOpen(false);
                                            document.getElementById('main')?.focus();
                                        }}
                                    >
                                        {translate('utilisateurs_04975')}
                                    </Item>

                                    <Separator color="gray100" direction="horizontal" />
                                </>
                            )}

                            {canAccessBackoffice(user.email) && (
                                <Item
                                    to={HeaventPaths.BACKOFFICE}
                                    onClick={() => {
                                        document.getElementById('main')?.focus();
                                    }}
                                >
                                    {translate('back_office_83072')}
                                </Item>
                            )}

                            <Item color="red" onClick={signOut}>
                                {translate('se_d_connecter_74151')}
                            </Item>
                        </Flex>
                    </DropdownMenu>
                </Dropdown>
            </Flex>
        </Flex>
    );
});
