import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { EventProperty, EventsEventFragment } from 'common/src/generated/types';
import * as React from 'react';
import { EventsTagsCellInput } from './eventsTagsCellInput';
import { EventsUpdateCell } from './eventsUpdateCell';

interface IEventsTagsCellProps {
    event: EventsEventFragment;

    reload(): void;
}

export const EventsTagsCell = (props: IEventsTagsCellProps) => {
    const tags = React.useMemo(() => props.event.tags || [], [props.event]);
    const badges = React.useMemo(
        () =>
            tags.map((tag) => ({
                id: tag.id,
                color: 'gray',
                icon: null,
                text: tag.name
            })),
        [tags]
    );

    return (
        <EventsUpdateCell
            eventId={props.event.id}
            initialValue={tags.map((t) => t.id)}
            property={EventProperty.Tag}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <EventsTagsCellInput setValue={setValue} value={value} />
            )}
            renderValue={() => <BadgesPlus badges={badges} />}
            updatable={true}
        />
    );
};
