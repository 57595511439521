import { Button } from 'common-front/src/designSystem/components/button';
import {
    closeOtherDropdowns,
    Dropdown
} from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Modal } from 'common-front/src/designSystem/components/modal/modal';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { EventsEventFragment } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { EventsPaths } from 'common/src/util/paths/eventsPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { getStateColor, startAtEndAtToString } from 'common/src/vo/event';
import * as React from 'react';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { EventArchiveModal } from '../eventArchiveModal';
import { EventDuplicateModal } from '../eventDuplicateModal';

interface IEventsEventCardProps {
    event: EventsEventFragment;

    reload(): void;
}

export const EventsEventCard = (props: IEventsEventCardProps) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const { isOrganizationAdmin } = useOrganizationContext();
    const dateTimeService = useService(DateTimeService);
    const [isDuplicateOpen, setIsDuplicateOpen] = React.useState(false);
    const [isArchiveOpen, setIsArchiveOpen] = React.useState(false);

    return (
        <>
            <Flex
                align="center"
                css={{
                    background: 'white',
                    bd: true,
                    padding: '$4 $6'
                }}
                gap="4"
            >
                <Flex css={{ flex: '1' }}>
                    <LinkText to={EventsPaths.EVENT({ organizationId, eventId: props.event.id })}>
                        {props.event.name}
                    </LinkText>
                </Flex>

                <Flex css={{ flex: '1' }} gap="8">
                    <Flex direction="column" gap="1" width={200}>
                        <Box font="gray500 textXs regular">{translate('date_de_l_v_ne_69973')}</Box>

                        <Box font="gray800 textSm medium">
                            {startAtEndAtToString(
                                dateTimeService,
                                props.event.startAt,
                                props.event.endAt
                            )}
                        </Box>
                    </Flex>

                    <Flex direction="column" gap="1" width={70}>
                        <Box font="gray500 textXs regular">{translate('status_06428')}</Box>

                        <Flex>
                            <Badge color={getStateColor(props.event.state)}>
                                {translate(props.event.state)}
                            </Badge>
                        </Flex>
                    </Flex>

                    <Flex direction="column" gap="1">
                        <Box font="gray500 textXs regular">{translate('tags_79499')}</Box>

                        <Flex gap="2">
                            {props.event.tags.map((tag) => (
                                <Badge key={tag.id} size="sm">
                                    {tag.name}
                                </Badge>
                            ))}
                        </Flex>
                    </Flex>
                </Flex>

                <Flex gap="3">
                    <Button to={EventsPaths.EVENT({ organizationId, eventId: props.event.id })}>
                        {translate('voir_l_v_nemen_72358')}
                    </Button>

                    {isOrganizationAdmin && (
                        <Dropdown>
                            <Trigger>
                                <Button color="white" leftIcon="ellipsis-vertical" />
                            </Trigger>

                            <Menu placement="bottom-end">
                                <ItemIcon
                                    icon="pen"
                                    to={HeaventPaths.EDIT_EVENT(organizationId, props.event.id)}
                                >
                                    {translate('_diter_62574')}
                                </ItemIcon>

                                <ItemIcon
                                    icon="clone"
                                    onClick={(e) => {
                                        closeOtherDropdowns(e.target);
                                        setIsDuplicateOpen(true);
                                    }}
                                >
                                    {translate('dupliquer_28256')}
                                </ItemIcon>

                                <ItemIcon
                                    color="red"
                                    icon="trash-can"
                                    onClick={(e) => {
                                        closeOtherDropdowns(e.target);
                                        setIsArchiveOpen(true);
                                    }}
                                >
                                    {translate('archiver_26131')}
                                </ItemIcon>
                            </Menu>
                        </Dropdown>
                    )}
                </Flex>
            </Flex>

            <Modal.Root
                isOpen={isDuplicateOpen}
                onClose={() => {
                    setIsDuplicateOpen(false);
                }}
            >
                <Modal.Portal>
                    <EventDuplicateModal
                        event={props.event}
                        reload={props.reload}
                        onClose={() => {
                            setIsDuplicateOpen(false);
                        }}
                    />
                </Modal.Portal>
            </Modal.Root>

            {isArchiveOpen && (
                <EventArchiveModal
                    event={props.event}
                    organizationId={organizationId}
                    onClose={() => setIsArchiveOpen(false)}
                    onSuccess={props.reload}
                />
            )}
        </>
    );
};
