import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Flex } from 'common/src/designSystem/components/flex';
import { EventId, EventsSortAttributes, SortDirection } from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useEventsQuery } from '../../../generated/graphqlHooks';
import { EventsEventCard } from './eventsEventCard';
import { EventsEventCardSkeleton } from './eventsEventCardSkeleton';

export const EventsCards = () => {
    const {
        params: { organizationId }
    } = useHeavent();
    const { nameDebounced, predicates, limit, offset, setLimit, setOffset } = useSegmentsContext<
        EventId,
        EventsSortAttributes
    >();
    const { data, isLoading, reload } = useEventsQuery({
        organizationId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : null,
        predicates,
        limit,
        offset,
        sort: { attribute: EventsSortAttributes.StartAt, direction: SortDirection.Desc }
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.data?.rows);

    return (
        <>
            <Flex
                css={{
                    background: '$gray100',
                    borderLeft: '1px solid $gray200',
                    borderRight: '1px solid $gray200',
                    borderTop: '1px solid $gray200',
                    flex: '1',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    padding: '$4'
                }}
                direction="column"
                gap="3"
                width={1}
            >
                {isLoading ? (
                    <>
                        <EventsEventCardSkeleton />
                        <EventsEventCardSkeleton />
                        <EventsEventCardSkeleton />
                    </>
                ) : (
                    (data.data?.rows.nodes ?? []).map((event) => (
                        <EventsEventCard key={event.id} event={event} reload={reload} />
                    ))
                )}
            </Flex>

            <PaginationRow css={{ borderTop: '1px solid $gray200' }}>
                <PaginationCell
                    limit={limit}
                    numberOfPages={numberOfPages}
                    offset={offset}
                    setLimit={setLimit}
                    setOffset={setOffset}
                    showLimits={true}
                    totalCount={totalCount}
                />
            </PaginationRow>
        </>
    );
};
