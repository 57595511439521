export const LocalStorageKeys = {
    AUTH_EMAIL: 'heavent-auth-email',
    BACKOFFICE_INVOICES_COLUMNS: 'heavent-backoffice-invoices-columns',
    BACKOFFICE_IS_ACTIVE: 'heavent-backoffice-is-active',
    BACKOFFICE_IS_ADMIN: 'heavent-backoffice-is-admin',
    BACKOFFICE_SEARCH: 'heavent-backoffice-search',
    BACKOFFICE_SORT: 'heavent-backoffice-sort',
    CURRENT_EVENT_ID: 'heavent-current-event-id',
    CURRENT_EVENT_NAME: 'heavent-current-event-name',
    CURRENT_ORGANIZATION_ID: 'heavent-current-organization-id',
    EVENT_ID: 'heavent-event-id',
    HIDE_HUBSPOT: 'hide-hubspot',
    ORGANIZATION_ID: 'heavent-organization-id',
    VOLUNTEERS_IMPORT_FORMATS: 'heavent-volunteers-import-formats',
    VOLUNTEERS_IMPORT_MAPPINGS: (hash: string) => `heavent-volunteers-import-mappings-${hash}`,
    VOLUNTEERS_IMPORT_PRESETS: (hash: string) => `heavent-volunteers-import-presets-${hash}`
};
