import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import * as React from 'react';

export const EventsEventCardSkeleton = () => {
    const { translate } = useHeavent();

    return (
        <Flex
            align="center"
            css={{
                background: 'white',
                bd: true,
                minHeight: '75px',
                padding: '$4 $6'
            }}
            gap="4"
        >
            <Flex css={{ flex: '1' }}>
                <Skeleton borderRadius="$1" height={20} width={1} />
            </Flex>

            <Flex css={{ flex: '1' }} gap="8">
                <Flex direction="column" gap="1" width={200}>
                    <Box font="gray500 textXs regular">{translate('date_de_l_v_ne_69973')}</Box>

                    <Skeleton borderRadius="$1" height={20} width={1} />
                </Flex>

                <Flex direction="column" gap="1" width={70}>
                    <Box font="gray500 textXs regular">{translate('status_06428')}</Box>

                    <Skeleton borderRadius="$1" height={20} width={1} />
                </Flex>

                <Flex direction="column" gap="1">
                    <Box font="gray500 textXs regular">{translate('tags_79499')}</Box>

                    <Skeleton borderRadius="$1" height={20} width={1} />
                </Flex>
            </Flex>

            <Flex width={200} />
        </Flex>
    );
};
