import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { EmptyFormBox, FormBox } from 'common-front/src/designSystem/components/formBox';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CreateUpdateEventInfosFragment,
    CustomFieldWithConditionFragment,
    EventId,
    EventInput,
    Feature,
    OrganizationId
} from 'common/src/generated/types';
import { EventInputService, ICreateUpdateEventValues } from 'common/src/input/eventInput';
import { ValidateService } from 'common/src/services/validateService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { EventsPaths } from 'common/src/util/paths/eventsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { TagsForm } from '../../accreditations/create/tagsForm';
import {
    useCreateEventMutation,
    useEventCreateInfosQuery,
    useEventUpdateInfosQuery,
    useEventUpdateMutation
} from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { CreatePositionCustomFields } from '../../positions/create/createPositionCustomFields';
import { EventForm } from './eventForm';
import { EventOptions } from './eventOptions';

interface ICreateUpdateEventProps {
    eventCustomFields: CustomFieldWithConditionFragment[];
    initialValues: EventInput;
    isEdit: boolean;
    organization: CreateUpdateEventInfosFragment;

    mutate(args: { organizationId: OrganizationId; event: EventInput }): Promise<EventId>;
}

const CreateUpdateEvent = (props: ICreateUpdateEventProps) => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const eventInput = useService(EventInputService);
    const validateService = useService(ValidateService);
    const { hasFeature, reload } = useOrganizationContext();
    const centeredContainerRef = React.useRef<HTMLDivElement | null>(null);
    const initialValues = React.useMemo(
        () => ({
            event: props.initialValues
        }),
        [props.initialValues]
    );
    const areParametersOpen = React.useMemo(
        () =>
            props.initialValues.languages.length > 1 ||
            typeof props.initialValues.emailSenderId === 'number' ||
            !props.initialValues.dayStartTime.startsWith('00:00'),
        [props.initialValues]
    );
    const areCustomFieldsOpen = React.useMemo(
        () => props.isEdit && isNonEmptyArray(Object.keys(props.initialValues.fields)),
        [props.isEdit, props.initialValues]
    );
    const isDescriptionOpen = React.useMemo(
        () => isNonEmptyString(props.initialValues.description),
        [props.initialValues]
    );
    const areTagsOpen = React.useMemo(
        () => (props.isEdit ? isNonEmptyArray(props.initialValues.tagsIds) : false),
        [props.isEdit, props.initialValues]
    );

    return (
        <Form
            height={1}
            hideErrors={true}
            initialValues={initialValues}
            initialValuesEqual={() => true}
            render={({ form, handleSubmit, values }) => (
                <FullScreenPopup
                    button={
                        props.isEdit && (
                            <Button onClick={handleSubmit}>
                                {translate('mettre_jour_l_28846')}
                            </Button>
                        )
                    }
                    category={props.organization.name}
                    color="dark"
                    title={
                        props.isEdit
                            ? translate('mise_jour_de_17055', props.initialValues.name)
                            : translate('cr_ation_d_un_94106')
                    }
                >
                    <CenteredContainer ref={centeredContainerRef}>
                        <FormErrors />

                        <FormBox
                            hideToggle={true}
                            initialIsOpen={true}
                            subtitle={translate('param_trer_votr_94121')}
                            title={translate('dites_en_nous_u_13238')}
                        >
                            <EventForm form={form} values={values} />
                        </FormBox>

                        <Spacer height="6" />

                        <FormBox
                            initialIsOpen={areParametersOpen}
                            subtitle={translate('param_trer_les_43001')}
                            title={translate('options_avanc_e_12396')}
                        >
                            <EventOptions
                                change={form.change}
                                emailsSenders={props.organization.emailsSenders}
                                hasLanguagesFeature={hasFeature(Feature.Languages)}
                                showEmailSender={true}
                            />
                        </FormBox>

                        <Spacer height="6" />

                        <FormBox
                            initialIsOpen={areCustomFieldsOpen}
                            subtitle={translate('ajouter_des_inf_87361')}
                            title={translate('champs_de_formu_64126')}
                            onToggle={(isOpen) => {
                                if (!isOpen) {
                                    form.change('event.fields', {});
                                }
                            }}
                        >
                            <CreatePositionCustomFields
                                change={form.change}
                                customFields={props.eventCustomFields}
                                prefix="event."
                            />
                        </FormBox>

                        <Spacer height="6" />

                        <FormBox
                            initialIsOpen={isDescriptionOpen}
                            subtitle={`Ajoutez une description`}
                            title={'Description'}
                            onToggle={(isOpen) => {
                                if (!isOpen) {
                                    form.change('event.description', '');
                                }
                            }}
                        >
                            <RichEditor
                                label={
                                    <LabelOptional>{translate('description_58935')}</LabelOptional>
                                }
                                name="event.description"
                            />
                        </FormBox>

                        <Spacer height="6" />

                        <FormBox
                            initialIsOpen={areTagsOpen}
                            subtitle={translate('ajouter_des_tag_23887')}
                            title={translate('tags_79499')}
                            onToggle={(isOpen) => {
                                if (!isOpen) {
                                    form.change('event.tagsIds', []);
                                }
                            }}
                        >
                            <TagsForm
                                change={form.change}
                                name="event."
                                organizationId={organizationId}
                                tags={props.organization.tags.nodes}
                                tagsIds={values.event.tagsIds}
                            />
                        </FormBox>

                        <Spacer height="6" />

                        <EmptyFormBox>
                            <Button textAlign="center" onClick={handleSubmit}>
                                {props.isEdit
                                    ? translate('mettre_jour_l_28846')
                                    : translate('cr_er_l_v_neme_16731')}
                            </Button>
                        </EmptyFormBox>
                    </CenteredContainer>
                </FullScreenPopup>
            )}
            validate={validateService.validateForForm(eventInput.createUpdateEventSchema())}
            width={1}
            onShowErrors={() => {
                if (centeredContainerRef.current) {
                    centeredContainerRef.current.scrollTop = 0;
                }
            }}
            onSubmit={async (values: ICreateUpdateEventValues) => {
                const id = await props.mutate({
                    organizationId: organizationId as OrganizationId,
                    event: values.event
                });

                reload();

                if (props.isEdit) {
                    history.goBack(EventsPaths.EVENT({ organizationId, eventId: id }));
                } else {
                    history.replace(EventsPaths.EVENT({ organizationId, eventId: id }));
                }
            }}
        />
    );
};

export const CreateEvent = () => {
    const eventInput = useService(EventInputService);
    const {
        params: { organizationId },
        translate
    } = useHeavent();
    useTitle(translate('cr_er_l_v_neme_16731'));
    const { data, loader } = useEventCreateInfosQuery({ organizationId });
    const { mutate } = useCreateEventMutation({ redirectOnSuccess: true });

    return (
        loader || (
            <CreateUpdateEvent
                eventCustomFields={data.organization?.eventCustomFields.nodes ?? []}
                initialValues={eventInput.eventInputDefault()}
                isEdit={false}
                mutate={async (values) => {
                    const {
                        eventCreate: { id }
                    } = await mutate(values);

                    return id;
                }}
                organization={data.organization}
            />
        )
    );
};

export const UpdateEvent = () => {
    const eventInput = useService(EventInputService);
    const {
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const { data, loader } = useEventUpdateInfosQuery({ organizationId, eventId });
    useTitle(`${translate('update_event_09229')}: ${data.event?.name}`);
    const { mutate } = useEventUpdateMutation({ redirectOnSuccess: true });

    return (
        loader || (
            <CreateUpdateEvent
                eventCustomFields={data.organization?.eventCustomFields.nodes ?? []}
                initialValues={eventInput.eventInputDefault(data.event)}
                isEdit={true}
                mutate={async (values) => {
                    const {
                        eventUpdate: { id }
                    } = await mutate({
                        ...values,
                        eventId
                    });

                    return id;
                }}
                organization={data.organization}
            />
        )
    );
};
