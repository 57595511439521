import { ProgressBar } from 'common-front/src/designSystem/components/progressBar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { DashboardQuery, EventId, OrganizationId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';

interface IDashboardPositionCategory {
    organizationId: OrganizationId;
    eventId: EventId;
    category: DashboardQuery['event']['positionsCategories'][number];
    clickable: boolean;
}

export const DashboardPositionCategory = ({
    organizationId,
    eventId,
    category,
    clickable
}: IDashboardPositionCategory) => {
    const translate = useTranslate();

    return (
        <Row key={category.id}>
            <Cell>
                <Flex direction="column" gap="2" width={1}>
                    <Flex gap="3" justify="between">
                        {clickable ? (
                            <LinkText
                                to={PositionsPaths.POSITION_CATEGORY({
                                    organizationId,
                                    eventId,
                                    positionCategoryId: category.id
                                })}
                            >
                                {category.name}
                            </LinkText>
                        ) : (
                            <Box color="gray700">{category.name}</Box>
                        )}

                        <Box color="gray700">
                            {translate(
                                '_1_2_membres_04485',
                                category.assignedResources,
                                category.resources
                            )}
                        </Box>
                    </Flex>

                    <Box width={1}>
                        <ProgressBar percent={category.assignedResources / category.resources} />
                    </Box>
                </Flex>
            </Cell>
        </Row>
    );
};
