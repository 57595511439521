import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentHeader } from 'common-front/src/segments/segmentHeader';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { SegmentCustomFieldFragment } from 'common/src/generated/types';
import { CommonPaths } from 'common/src/util/paths/commonPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { Route } from 'react-router';
import { useUsersInfosExportMutation } from '../../generated/graphqlHooks';
import { CreateNewVolunteer } from '../../volunteers/create/createNewVolunteer';
import { VolunteersExportPanel } from '../../volunteers/export/volunteersExportPanel';

interface ICommunityUsersHeaderProps {
    customFields: SegmentCustomFieldFragment[];

    reload(): void;
}

export const CommunityUsersHeader = ({ customFields, reload }: ICommunityUsersHeaderProps) => {
    const {
        history,
        translate,
        params: { organizationId, segmentId }
    } = useHeavent();
    const { segment, columnsExport } = useSegmentsContext();
    const { mutate: usersInfosExport, isLoading: isUsersInfosExportLoading } =
        useUsersInfosExportMutation();
    const [addMemberOpen, setAddMemberOpen] = React.useState(false);
    const basePath = HeaventPaths.COMMUNITY_USERS_SEGMENT(organizationId, segmentId);

    return (
        <SegmentHeader>
            <Button
                color="white"
                leftIcon="cloud-arrow-down"
                to={CommonPaths.EXPORT.BASIC(basePath)}
            >
                {translate('exporter_59120')}
            </Button>

            <Dropdown>
                <Trigger>
                    <Button>{translate('ajouter_un_memb_33261')}</Button>
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon
                        icon="user-plus"
                        onClick={() => {
                            setAddMemberOpen(true);
                        }}
                    >
                        {translate('ajouter_un_nouv_29283')}
                    </ItemIcon>

                    <ItemIcon
                        icon="file-import"
                        onClick={() => {
                            history.push(HeaventPaths.COMMUNITY_VOLUNTEERS_IMPORT(organizationId));
                        }}
                    >
                        {translate('importer_via_un_61623')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon
                        icon="merge"
                        onClick={() => {
                            history.push(HeaventPaths.COMMUNITY_USERS_DUPLICATES(organizationId));
                        }}
                    >
                        {translate('g_rer_les_doubl_39469')}
                    </ItemIcon>

                    <ItemIcon
                        icon="file-import"
                        onClick={() => {
                            history.push(HeaventPaths.COMMUNITY_FILES_IMPORT(organizationId));
                        }}
                    >
                        {translate('importer_des_do_55420')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            {addMemberOpen && (
                <CreateNewVolunteer
                    organizationId={organizationId}
                    reload={reload}
                    showSendEmail={false}
                    subtitle={translate('cr_er_un_nouvea_48895')}
                    onClose={() => {
                        setAddMemberOpen(false);
                    }}
                />
            )}

            <Route path={`${basePath}/export`}>
                <VolunteersExportPanel
                    basePath={basePath}
                    customFields={customFields}
                    eventId={null}
                    isLoading={isUsersInfosExportLoading}
                    organizationId={organizationId}
                    requestExport={async (format, includeFiles) => {
                        const { jobId } = await usersInfosExport({
                            organizationId,
                            segmentId: segment.id,
                            format,
                            includeFiles,
                            columns: columnsExport
                        });

                        return jobId;
                    }}
                />
            </Route>
        </SegmentHeader>
    );
};
