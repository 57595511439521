import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { FilterPredicate } from 'common/src/vo/segment';
import { Operator, Predicate } from 'common/src/vo/segments/predicate';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { RadioText } from '../../designSystem/components/radio';

interface IAddIsTeamFilterProps {
    filterPredicate: FilterPredicate;

    onAdd(predicate: Predicate): void;
}

export const AddIsTeamFilter = (props: IAddIsTeamFilterProps) => {
    const translate = useTranslate();
    const [operator, setOperator] = React.useState<Operator>(
        props.filterPredicate.predicate?.operator ?? 'is'
    );

    return (
        <>
            <RadioText
                state={operator === 'is' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('is');
                }}
            >
                {translate('est_inscrit_en_86193')}
            </RadioText>

            <Spacer height="3" />

            <RadioText
                state={operator === 'isNot' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('isNot');
                }}
            >
                {translate('n_est_pas_inscr_25311')}
            </RadioText>

            <Spacer height="7" />

            <Flex>
                <Button
                    onClick={() => {
                        props.onAdd({
                            operator,
                            value: '',
                            slug: props.filterPredicate.filter.slug
                        });
                    }}
                >
                    {translate('ajouter_le_filt_54222')}
                </Button>
            </Flex>
        </>
    );
};
