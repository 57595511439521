import { executeTagsQuery } from 'common-front/src/generated/graphqlHooks';
import { useCachedDataLoading } from 'common-front/src/hooks/useCachedDataLoading';
import { OrganizationId, TagsQuery } from 'common/src/generated/types';
import * as React from 'react';

interface IEventsContext {
    getTags(): Promise<TagsQuery['organization']['tags']['nodes']>;
}

const EventsContext = React.createContext<IEventsContext>({} as any);

interface IEventsContextProviderProps {
    children: React.ReactNode;
    organizationId: OrganizationId;
}

export const EventsContextProvider = (props: IEventsContextProviderProps) => {
    const getTags = useCachedDataLoading(
        executeTagsQuery,
        { organizationId: props.organizationId },
        (d) => d.organization.tags.nodes
    );

    return (
        <EventsContext.Provider
            value={{
                getTags
            }}
        >
            {props.children}
        </EventsContext.Provider>
    );
};

export function useEventsContext() {
    return React.useContext(EventsContext);
}
