import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentHeader } from 'common-front/src/segments/segmentHeader';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';

export const CustomFieldsHeader = () => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const { segment } = useSegmentsContext();

    return (
        <SegmentHeader>
            <Button
                onClick={() => {
                    history.push(HeaventPaths.CREATE_CUSTOM_FIELD(organizationId, segment.id));
                }}
            >
                {translate('cr_er_un_champ_31226')}
            </Button>
        </SegmentHeader>
    );
};
