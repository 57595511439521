import { LinkText } from 'common/src/designSystem/components/linkText';
import { CustomFieldSlug, SegmentCustomFieldFragment } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Fields, FieldService } from 'common/src/vo/field';
import * as React from 'react';
import { FileS3Input } from '../../designSystem/components/file/fileS3Input';
import { useHeavent } from '../../hooks/useHeavent';
import { Accept } from '../../util/accept';
import { UpdateCell } from './updateCell';
import { useUpdateCellMutate } from './useUpdateCellMutate';

interface IUpdateFileCellProps {
    customField: SegmentCustomFieldFragment;
    fields: Fields;
    updatable: boolean;

    mutate(value: string, slug: CustomFieldSlug): Promise<any>;
    reload(): void;
}

export const UpdateFileCell = (props: IUpdateFileCellProps) => {
    const {
        params: { organizationId }
    } = useHeavent();
    const fieldService = useService(FieldService);
    const valueString = fieldService.getValueString(props.customField, props.fields, {
        fileReturnValue: 'name'
    });
    const url = fieldService.getValueString(props.customField, props.fields, {
        fileReturnValue: 'url'
    });
    const initialValue = React.useMemo(
        () => fieldService.getValue(props.customField, props.fields),
        [props.customField, props.fields]
    );
    const mutate = useUpdateCellMutate(props.customField, props.mutate);

    return (
        <UpdateCell
            initialValue={initialValue}
            mutate={mutate}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <FileS3Input
                    accept={[Accept.Images, Accept.Pdf]}
                    acl="private"
                    label={props.customField.name}
                    organizationId={organizationId}
                    value={value}
                    onChange={setValue}
                />
            )}
            renderValue={() => (
                <LinkText css={{ fontWeight: '$regular' }} to={url}>
                    {valueString}
                </LinkText>
            )}
            updatable={props.updatable}
        />
    );
};
