import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { FilterPredicate } from 'common/src/vo/segment';
import { Operator, Predicate } from 'common/src/vo/segments/predicate';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { RadioText } from '../../designSystem/components/radio';
import { RichSelect } from '../../designSystem/components/richSelect/richSelect';
import { TextInput } from '../../designSystem/components/textInput';

interface IAddWishedPositionsFilterProps {
    filterPredicate: FilterPredicate;

    onAdd(predicate: Predicate): void;
}

export const AddWishedPositionsFilter = (props: IAddWishedPositionsFilterProps) => {
    const translate = useTranslate();
    const [operator, setOperator] = React.useState<Operator>(
        props.filterPredicate.predicate?.operator ?? 'is'
    );
    const options = React.useMemo(() => {
        if (isNonEmptyArray(props.filterPredicate.filter.values)) {
            return props.filterPredicate.filter.values.map(({ id, name }) => (
                <option key={id} value={id}>
                    {translate(name)}
                </option>
            ));
        } else if (isNonEmptyArray(props.filterPredicate.filter.valuesGroups)) {
            return props.filterPredicate.filter.valuesGroups.map(({ name, values }, index) => (
                <optgroup key={index} label={name}>
                    {values.map((v) => (
                        <option key={v.id} value={v.id}>
                            {v.name}
                        </option>
                    ))}
                </optgroup>
            ));
        } else {
            return [];
        }
    }, [props.filterPredicate.filter]);
    const [value, setValue] = React.useState<any[]>(
        isNonEmptyArray(props.filterPredicate.predicate?.value)
            ? props.filterPredicate.predicate!.value
            : []
    );
    const [value2, setValue2] = React.useState(props.filterPredicate.predicate?.value2 ?? 1);
    const valid = isNonEmptyArray(value);

    return (
        <>
            <RadioText
                state={operator === 'is' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('is');
                }}
            >
                {translate('est_25967')}
            </RadioText>

            {operator === 'is' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <RichSelect
                            isSearchVisible={true}
                            isSelectAllVisible={true}
                            multiple={true}
                            placeholder={translate('s_lectionner_de_52380')}
                            values={value}
                            onChange={setValue}
                        >
                            {options}
                        </RichSelect>
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'isNot' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('isNot');
                }}
            >
                {translate('n_est_pas_97826')}
            </RadioText>

            {operator === 'isNot' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <RichSelect
                            isSearchVisible={true}
                            isSelectAllVisible={true}
                            multiple={true}
                            placeholder={translate('s_lectionner_de_52380')}
                            values={value}
                            onChange={setValue}
                        >
                            {options}
                        </RichSelect>
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'contains' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('contains');
                }}
            >
                {translate('est_le_choix_07450')}
            </RadioText>

            {operator === 'contains' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <TextInput shouldParseAsInt={true} value={value2} onChange={setValue2} />
                    </Flex>

                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <RichSelect
                            isSearchVisible={true}
                            isSelectAllVisible={true}
                            multiple={true}
                            placeholder={translate('s_lectionner_de_52380')}
                            values={value}
                            onChange={setValue}
                        >
                            {options}
                        </RichSelect>
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="7" />

            <Flex>
                <Button
                    disabled={!valid}
                    onClick={() => {
                        props.onAdd({
                            operator,
                            value,
                            value2,
                            slug: props.filterPredicate.filter.slug
                        });
                    }}
                >
                    {translate('ajouter_le_filt_54222')}
                </Button>
            </Flex>
        </>
    );
};
