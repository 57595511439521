import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { Event, OrganizationId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useEventsMassArchiveMutation } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';

interface IArchiveEventProps {
    event: Pick<Event, 'id' | 'name'>;
    organizationId: OrganizationId;

    onClose(): void;
    onSuccess(): void;
}

export const EventArchiveModal = (props: IArchiveEventProps) => {
    const translate = useTranslate();
    const { mutate } = useEventsMassArchiveMutation();
    const { reload } = useOrganizationContext();

    return (
        <DeleteModal
            buttonText={translate('archiver_26131')}
            subtext={translate('_tes_vous_s_r_d_76724')}
            text={translate('archivage_de_31255', props.event.name)}
            onClose={props.onClose}
            onDelete={async () => {
                await mutate({
                    organizationId: props.organizationId,
                    selecteds: { ids: [props.event.id] }
                });

                reload();
                props.onSuccess();
            }}
        />
    );
};
