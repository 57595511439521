import { injectable } from 'inversify';
import * as yup from 'yup';
import { CsvDocumentInput } from '../generated/types';
import { TranslationService } from '../services/translationService';
import { CsvInputService, CsvSettings } from './csvInput';
import { InputService } from './inputService';

export type DelegationsImportInput = {
    csv: CsvDocumentInput;
};

export const DELEGATIONS_IMPORT_FIELDS_DEFAULT = {
    /* Required */
    Name: 'name',
    FormId: 'formId',

    /* Optional */
    LeadersEmails: 'leadersEmails',
    LeadersFirstNames: 'leadersFirstnames',
    LeadersLastNames: 'leadersLastnames',
    Deadline: 'deadline',
    MaxResources: 'maxResources',
    PrivateNote: 'privateNote',
    Tags: 'tags'
};

const DELEGATIONS_IMPORT_FIELDS_REQUIRED = [
    DELEGATIONS_IMPORT_FIELDS_DEFAULT.Name,
    DELEGATIONS_IMPORT_FIELDS_DEFAULT.FormId
];

@injectable()
export class DelegationsImportInputService extends InputService {
    constructor(
        private csvInputService: CsvInputService,
        translationService: TranslationService
    ) {
        super(translationService);
    }

    default(): { input: DelegationsImportInput } {
        return {
            input: {
                csv: this.csvInputService.csvDocumentInputDefault(null)
            }
        };
    }

    schema({ delimiter, newline, quoteChar }: CsvSettings) {
        return yup.object().shape({
            input: yup.object().shape({
                csv: this.csvInputService.schema({
                    defaultFields: DELEGATIONS_IMPORT_FIELDS_DEFAULT,
                    requiredFields: DELEGATIONS_IMPORT_FIELDS_REQUIRED,
                    delimiter,
                    hasCustomFields: false,
                    newline,
                    quoteChar
                })
            })
        });
    }
}
