import { MobileOverlay } from 'common-front/src/components/mobileOverlay/mobileOverlay';
import { styled } from 'common/src/designSystem/components/stitches';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { useEventsQuery } from '../../generated/graphqlHooks';
import { EventSwitchMobile } from './eventSwitchMobile';

const _h3 = styled('h3', {
    color: '$gray800',
    fontWeight: '$bold',
    margin: '$3 $4'
});

export const EventSwitchSheetMobile = () => {
    const { organizationId, eventId } = useParams();
    const { loader, data } = useEventsQuery({ organizationId });
    const history = useHistory();
    const translate = useTranslate();

    const onClose = () => {
        history.push(HeaventPaths.DASHBOARD(organizationId, eventId));
    };

    return (
        <MobileOverlay onClose={onClose}>
            <_h3>{translate('liste_de_vos_v_44993')}</_h3>
            {loader || <EventSwitchMobile events={data.data.rows.nodes} />}
        </MobileOverlay>
    );
};
