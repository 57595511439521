import { MassActionSelectedsInput, SegmentId } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { Predicates } from 'common/src/vo/segments/predicate';
import { uniq } from 'lodash-es';
import * as React from 'react';
import { ICheckboxState } from '../designSystem/components/checkbox';

export type IMassActionsState<T, U = null> = {
    id: T;
    disabled?: boolean;
    infos?: U;
    state: ICheckboxState;
};

type MassActionsStates<T, U = null> = { [key: string]: IMassActionsState<T, U> };

export interface IUseMassActionsResult<T, U = null> {
    isAllSelected: boolean;
    numberOfSelected: number;
    selectAllState: ICheckboxState;
    selecteds: Array<IMassActionsState<T, U>>;
    selectedsIds: T[];
    states: MassActionsStates<T, U>;

    getSelectedsInput(
        predicates: Emptyable<Predicates>,
        segmentId: Emptyable<SegmentId>
    ): MassActionSelectedsInput;
    setIsAllSelected(isAllSelected: boolean): void;
    setStates(states: MassActionsStates<T, U>): void;
    toggleRow(key: string, newState: ICheckboxState): void;
    toggleSelectAll(state: ICheckboxState): void;
}

export function useMassActions<T, U = null>(
    initial: Record<string, IMassActionsState<T, U>>
): IUseMassActionsResult<T, U> {
    const [states, setStates] = React.useState(initial);
    const [isAllSelected, setIsAllSelected] = React.useState(false);
    const computed = React.useMemo(() => {
        const allEnabled = Object.values(states).filter((s) => s.disabled !== true);
        const selecteds = allEnabled.filter((s) => s.state === 'checked');
        const selectedsIds = selecteds.map((s) => s.id);
        const uniqStates = uniq(allEnabled.map(({ state }) => state));
        const selectAllState: ICheckboxState =
            uniqStates.length === 2
                ? 'indeterminate'
                : uniqStates[0] === 'checked'
                  ? 'checked'
                  : 'unchecked';

        return {
            numberOfSelected: selecteds.length,
            selectAllState,
            selecteds,
            selectedsIds
        };
    }, [states]);
    const toggleRow = React.useCallback(
        (key: string, newState: ICheckboxState) => {
            setStates((currentStates) => ({
                ...currentStates,
                [key]: {
                    ...currentStates[key],
                    state: newState
                }
            }));

            if (newState === 'unchecked') {
                setIsAllSelected(false);
            }
        },
        [states, setStates, setIsAllSelected]
    );
    const toggleSelectAll = React.useCallback(
        (newState: ICheckboxState) => {
            setStates(
                Object.fromEntries(
                    Object.entries(states)
                        .filter(([_, value]) => value.disabled !== true)
                        .map(([key, value]) => [
                            key,
                            {
                                ...value,
                                state: newState
                            }
                        ])
                )
            );

            if (newState === 'unchecked') {
                setIsAllSelected(false);
            }
        },
        [states, setStates, setIsAllSelected]
    );
    const getSelectedsInput = React.useCallback(
        (predicates: Emptyable<Predicates>, segmentId: Emptyable<SegmentId>) =>
            isAllSelected
                ? predicates
                    ? { ids: null, predicates, segmentId: null }
                    : { ids: null, predicates: null, segmentId: segmentId }
                : {
                      ids: computed.selectedsIds as number[],
                      predicates: null,
                      segmentId: null
                  },
        [isAllSelected, computed]
    );

    return {
        getSelectedsInput,
        isAllSelected,
        numberOfSelected: computed.numberOfSelected,
        selectAllState: computed.selectAllState,
        selecteds: computed.selecteds,
        selectedsIds: computed.selectedsIds,
        setIsAllSelected,
        setStates,
        states,
        toggleRow,
        toggleSelectAll
    };
}
