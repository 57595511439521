import { Page } from 'common-front/src/components/page/page';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SearchRow } from 'common-front/src/segments/searchRow';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { SegmentTableHeader } from 'common-front/src/segments/segmentTableHeader';
import { SegmentCustomFieldFragment } from 'common/src/generated/types';
import { EventsRendering } from 'common/src/vo/rendering';
import * as React from 'react';
import { useOrganizationContext } from '../../organization/organizationContext';
import { EventsCards } from './card/eventsCards';
import { EventsHeader } from './eventsHeader';
import { EventsList } from './list/eventsList';

interface IEventsContentProps {
    customFields: SegmentCustomFieldFragment[];
}

export const EventsContent = (props: IEventsContentProps) => {
    const { translate } = useHeavent();
    const { isOrganizationAdmin } = useOrganizationContext();
    const { rendering } = useSegmentsContext();
    const showEditColumns = isOrganizationAdmin && rendering === EventsRendering.List;
    const showEditMode = isOrganizationAdmin && rendering === EventsRendering.List;

    return (
        <>
            <EventsHeader />

            <Page.Content>
                <SegmentTableHeader
                    searchRow={
                        <SearchRow
                            searchPlaceholder={translate('rechercher_un_40024')}
                            showEditColumns={showEditColumns}
                            showEditMode={showEditMode}
                            showSearchColumns={false}
                        />
                    }
                />

                {rendering === EventsRendering.List ? (
                    <EventsList customFields={props.customFields} />
                ) : (
                    <EventsCards />
                )}
            </Page.Content>
        </>
    );
};
