import { Loader } from 'common-front/src/components/loader/loader';
import { Page } from 'common-front/src/components/page/page';
import { DelegationMembersActions } from 'common-front/src/delegations/members/delegationMembersActions';
import { FilterColumns } from 'common-front/src/filtersColumns/filtersColumns';
import {
    executeUserQuery,
    executeUsersQuery,
    executeVolunteerQuery,
    executeVolunteersQuery
} from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useLocalOrganizationState } from 'common-front/src/hooks/useLocalOrganization';
import { useSlugToCustomField } from 'common-front/src/hooks/useSlugToCustomField';
import { RichTableList } from 'common-front/src/richTable/richTableList';
import { CommunityUserCells } from 'common-front/src/users/list/communityUserCells';
import { VolunteerCells } from 'common-front/src/volunteers/list/volunteerCells';
import { useService } from 'common/src/util/dependencies/dependencies';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import { VolunteersRendering } from 'common/src/vo/rendering';
import {
    columnsToIncludes as usersColumnsToIncludes,
    UsersSegmentsService
} from 'common/src/vo/segments/usersSegmentsService';
import {
    columnsToIncludes as volunteersColumnsToIncludes,
    VolunteersSegmentsService
} from 'common/src/vo/segments/volunteersSegmentsService';
import * as React from 'react';
import { useDelegationCategoryMembersInfosQuery } from '../../../generated/graphqlHooks';

export const DelegationCategoryMembers = () => {
    const {
        params: { organizationId, eventId, delegationCategoryId }
    } = useHeavent();
    const volunteersSegmentsService = useService(VolunteersSegmentsService);
    const usersSegmentsService = useService(UsersSegmentsService);
    const [columns, setColumns, isColumnsLoading] = useLocalOrganizationState(
        organizationId,
        'delegationCategoryMembers.columns',
        ['name', 'delegations']
    );
    const [limit, setLimit, isLimitLoading] = useLocalOrganizationState(
        organizationId,
        'delegationCategoryMembers.limit',
        DEFAULT_PAGINATION_LIMIT
    );
    const [predicates, setPredicates, isPredicatesLoading] = useLocalOrganizationState(
        organizationId,
        'delegationCategoryMembers.predicates',
        []
    );
    const [sort, setSort, isSortLoading] = useLocalOrganizationState(
        organizationId,
        'delegationCategoryMembers.sort',
        null
    );
    const { data, isLoading: areInfosLoading } = useDelegationCategoryMembersInfosQuery({
        organizationId,
        eventId
    });
    const isLoading =
        isColumnsLoading ||
        isLimitLoading ||
        isPredicatesLoading ||
        isSortLoading ||
        areInfosLoading;
    const customFields = React.useMemo(
        () => data.organization?.customFields.nodes ?? [],
        [data.organization]
    );
    const slugToCustomField = useSlugToCustomField(customFields);
    const filters = React.useMemo(
        () =>
            data.organization
                ? eventId
                    ? volunteersSegmentsService.getDelegationCategoryMembersFilters(
                          eventId,
                          customFields,
                          data.organization.event!
                      )
                    : usersSegmentsService.getDelegationMembersFilters(customFields)
                : [],
        [eventId, customFields]
    );
    const possibleColumns = React.useMemo(
        () =>
            eventId
                ? volunteersSegmentsService.getDelegationCategoryMembersPossibleColumns(
                      customFields
                  )
                : usersSegmentsService.getDelegationMembersPossibleColumns(customFields),
        [eventId, customFields]
    );

    return isLoading ? (
        <Loader />
    ) : (
        <Page.Content>
            <FilterColumns
                columns={columns}
                columnsSearch={[]}
                columnsToIncludes={eventId ? volunteersColumnsToIncludes : usersColumnsToIncludes}
                eventId={eventId}
                filters={filters}
                getMassActionId={(row: any) => (eventId ? row.userInfo.id : row.id)}
                limit={limit}
                loadDataList={(eventId ? executeVolunteersQuery : executeUsersQuery) as any}
                loadDataListParams={{
                    delegationCategoryId,
                    includeCanRegistrationStateUpdate: false,
                    includeIsEditable: false
                }}
                loadRow={(eventId ? executeVolunteerQuery : executeUserQuery) as any}
                loadRowParams={{
                    includeCanRegistrationStateUpdate: false,
                    includeIsEditable: false
                }}
                organizationId={organizationId}
                possibleColumns={possibleColumns}
                predicates={predicates}
                renderActions={(massActions, _totalCount, reload) => (
                    <DelegationMembersActions
                        customFields={customFields}
                        massActions={massActions}
                        showWishedAccreditationsEdit={false}
                        onMassEditSuccess={reload}
                    />
                )}
                rendering={VolunteersRendering.List}
                setColumns={setColumns}
                setLimit={setLimit}
                setPredicates={setPredicates}
                setSort={setSort}
                showEditColumns={true}
                showEditMode={true}
                showFilters={true}
                showMassActions={true}
                showRowDropdown={false}
                showRowEdit={false}
                showSearchColumns={false}
                sort={sort}
            >
                <RichTableList
                    renderCell={(column, row: any, reload) =>
                        eventId ? (
                            <VolunteerCells
                                canAccredit={true}
                                canAssign={false}
                                canChangeDelegation={true}
                                canClickMemberName={true}
                                column={column}
                                customField={slugToCustomField.get(column.slug)}
                                eventId={eventId}
                                reloadRow={reload}
                                row={row}
                                weezevent={null}
                            />
                        ) : (
                            <CommunityUserCells
                                canClickMemberName={true}
                                column={column}
                                customField={slugToCustomField.get(column.slug)}
                                reloadRow={reload}
                                row={row}
                                updatable={true}
                            />
                        )
                    }
                />
            </FilterColumns>
        </Page.Content>
    );
};
