import { Page } from 'common-front/src/components/page/page';
import { SelectSegment } from 'common-front/src/segments/massActions/selectSegment';
import { SearchRow } from 'common-front/src/segments/searchRow';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { SegmentTableHeader } from 'common-front/src/segments/segmentTableHeader';
import {
    SegmentCustomFieldFragment,
    UsersInfoId,
    VolunteersRegistrationsSortAttributes
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { VolunteersRendering } from 'common/src/vo/rendering';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { VolunteersAccreditationsMatrix } from './accreditationsMatrix/volunteersAccreditationsMatrix';
import { VolunteersList } from './list/volunteersList';
import { VolunteersPlanning } from './planning/volunteersPlanning';
import { VolunteersActions } from './volunteersActions';
import { VolunteersHeader } from './volunteersHeader';

interface IVolunteersContentProps {
    customFields: SegmentCustomFieldFragment[];
    endAt: DateTime;
    startAt: DateTime;
    weezevent: Emptyable<{
        eventId: number;
        organizationId: number;
    }>;
}

export const VolunteersContent = React.memo(function VolunteersContent(
    props: IVolunteersContentProps
) {
    const translate = useTranslate();
    const { isEventAdmin } = useEventContext();
    const {
        limit,
        massActions,
        onMassEditSuccess,
        predicates,
        reloadKey,
        rendering,
        segment,
        setReloadKey
    } = useSegmentsContext<UsersInfoId, VolunteersRegistrationsSortAttributes>();
    const showEditColumns = isEventAdmin && rendering === VolunteersRendering.List;
    const showEditMode =
        (isEventAdmin && rendering === VolunteersRendering.AccreditationsMatrix) ||
        rendering === VolunteersRendering.List;

    return (
        <>
            <VolunteersHeader
                customFields={props.customFields}
                reload={() => {
                    setReloadKey(reloadKey + 1);
                }}
            />

            <Page.Content>
                <SegmentTableHeader
                    searchRow={
                        <SearchRow
                            actions={
                                <VolunteersActions
                                    massActions={massActions}
                                    predicates={predicates}
                                    showOnlyMassEdit={false}
                                    totalNumberOfMembers={segment.count}
                                    onMassEditSuccess={onMassEditSuccess}
                                />
                            }
                            searchPlaceholder={translate('rechercher_un_m_05904')}
                            showEditColumns={showEditColumns}
                            showEditMode={showEditMode}
                            showSearchColumns={true}
                        />
                    }
                    selectSegment={
                        <SelectSegment
                            pageSelectedText={translate('les_1_membres_00895', limit)}
                            segmentSelectedText={translate(
                                'les_1_membres_56748',
                                segment.count,
                                segment.name
                            )}
                        />
                    }
                />

                {isEventAdmin && rendering === VolunteersRendering.AccreditationsMatrix ? (
                    <VolunteersAccreditationsMatrix />
                ) : rendering === VolunteersRendering.Calendar ? (
                    <VolunteersPlanning endAt={props.endAt} startAt={props.startAt} />
                ) : (
                    <VolunteersList customFields={props.customFields} weezevent={props.weezevent} />
                )}
            </Page.Content>
        </>
    );
});
