import { BaseFlexProps, Flex, pickBaseFlexProps } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IContentProps extends BaseFlexProps {
    children: React.ReactNode;
}

export const Content = (props: IContentProps) => (
    <Flex
        {...pickBaseFlexProps(props)}
        css={{
            background: '$gray50',
            flex: '1',
            padding: '$7',
            ...props.css
        }}
        direction="column"
    >
        {props.children}
    </Flex>
);
