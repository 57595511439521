import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Link } from 'common/src/designSystem/components/link';
import { CommonEnvVars } from 'common/src/envVars';
import * as React from 'react';
import LogoWeezaccess from 'svgComponents/logo/logoWeezaccess.svg';
import LogoWeezticket from 'svgComponents/logo/logoWeezticket.svg';

interface IOrganizationMenuWeezeventProps {
    weezeventOrganizationId: number;
}

export const OrganizationMenuWeezevent = (props: IOrganizationMenuWeezeventProps) => {
    const { translate } = useHeavent();
    const ticketUrl = `https://admin.${CommonEnvVars.WEEZEVENT_DOMAIN}/ticket/O${props.weezeventOrganizationId}`;
    const accessUrl = `https://admin.${CommonEnvVars.WEEZEVENT_DOMAIN}/access/O${props.weezeventOrganizationId}`;

    return (
        <Dropdown>
            <Trigger>
                <Flex
                    align="center"
                    css={{
                        borderRadius: '$2',
                        padding: '$2',
                        '&:hover': { background: '$gray700' }
                    }}
                    justify="center"
                >
                    <Flex align="center" css={{ fontSize: '$textMd' }}>
                        <I icon="grid" iconStyle="solid" />
                    </Flex>
                </Flex>
            </Trigger>

            <Menu css={{ padding: '0' }} placement="bottom-end">
                <Flex css={{ padding: '$3 $4' }} direction="column" gap="3">
                    <Box color="gray800" fontWeight="medium">
                        Passer à
                    </Box>

                    <Flex direction="column" gap="4">
                        <Link to={ticketUrl}>
                            <Flex align="center" gap="3">
                                <Box
                                    css={{
                                        '& svg': { height: '100%' }
                                    }}
                                    height={30}
                                    width={30}
                                >
                                    <LogoWeezticket />
                                </Box>

                                <Flex direction="column">
                                    <Box color="gray800" fontWeight="medium">
                                        WeezTicket
                                    </Box>

                                    <Box color="gray500" fontWeight="medium">
                                        {translate('billetterie_53780')}
                                    </Box>
                                </Flex>
                            </Flex>
                        </Link>

                        <Link to={accessUrl}>
                            <Flex align="center" gap="3">
                                <Box
                                    css={{
                                        '& svg': { height: '100%' }
                                    }}
                                    height={30}
                                    width={30}
                                >
                                    <LogoWeezaccess />
                                </Box>

                                <Flex direction="column">
                                    <Box color="gray800" fontWeight="medium">
                                        WeezAccess
                                    </Box>

                                    <Box color="gray500" fontWeight="medium">
                                        {translate('contr_le_d_acc_03854')}
                                    </Box>
                                </Flex>
                            </Flex>
                        </Link>
                    </Flex>
                </Flex>
            </Menu>
        </Dropdown>
    );
};
