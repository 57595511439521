import {
    CustomFieldPrivacy,
    FormId,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import { VolunteersRendering } from 'common/src/vo/rendering';
import {
    columnsToIncludes,
    VolunteersSegmentsService
} from 'common/src/vo/segments/volunteersSegmentsService';
import { compact } from 'lodash-es';
import * as React from 'react';
import { Loader } from '../../components/loader/loader';
import { FilterColumns } from '../../filtersColumns/filtersColumns';
import {
    executeVolunteerQuery,
    executeVolunteersQuery,
    useEventDelegationMembersInfosQuery
} from '../../generated/graphqlHooks';
import { useHeavent } from '../../hooks/useHeavent';
import { useLocalOrganizationState } from '../../hooks/useLocalOrganization';
import { useSlugToCustomField } from '../../hooks/useSlugToCustomField';
import { RichTableList } from '../../richTable/richTableList';
import { VolunteerCells } from '../../volunteers/list/volunteerCells';
import { VolunteerPanels } from '../../volunteers/list/volunteerPanels';
import { DelegationMemberRowDropdown } from './delegationMemberRowDropdown';
import { DelegationMembersActions } from './delegationMembersActions';

interface IDelegationMembersProps {
    canAccredit: boolean;
    canClickMemberName: boolean;
    canEditMembers: boolean;
    columns: string[];
    customBadges: string[];
    deleteFromEvent: boolean;
    isEventAdmin: boolean;
    formId: FormId;
    showDeleteFromEvent: boolean;
    showDocumentsDownload: boolean;

    getEditPath(member: VolunteerRegistrationFragment): string;
}

export const EventDelegationMembers = (props: IDelegationMembersProps) => {
    const {
        params: { organizationId, eventId, delegationId }
    } = useHeavent();
    const volunteersSegmentsService = useService(VolunteersSegmentsService);
    const [columns, setColumns, isColumnsLoading] = useLocalOrganizationState(
        organizationId,
        'eventDelegationMembers.columns',
        props.columns
    );
    const [limit, setLimit, isLimitLoading] = useLocalOrganizationState(
        organizationId,
        'eventDelegationMembers.limit',
        DEFAULT_PAGINATION_LIMIT
    );
    const [predicates, setPredicates, isPredicatesLoading] = useLocalOrganizationState(
        organizationId,
        'eventDelegationMembers.predicates',
        []
    );
    const [sort, setSort, isSortLoading] = useLocalOrganizationState(
        organizationId,
        'eventDelegationMembers.sort',
        null
    );
    const { data, isLoading: areInfosLoading } = useEventDelegationMembersInfosQuery({
        organizationId,
        eventId,
        delegationId,
        privacy: props.isEventAdmin ? CustomFieldPrivacy.All : CustomFieldPrivacy.Public
    });
    const isLoading =
        isColumnsLoading ||
        isLimitLoading ||
        isPredicatesLoading ||
        isSortLoading ||
        areInfosLoading;
    const customFields = React.useMemo(() => {
        if (props.isEventAdmin) {
            return data.organization?.customFields.nodes ?? [];
        } else {
            return compact(data.event?.delegation.form.elements.map((e) => e.customField) || []);
        }
    }, [props.isEventAdmin, data.organization, data.event]);
    const slugToCustomField = useSlugToCustomField(customFields);
    const filters = React.useMemo(() => {
        if (data.event) {
            return volunteersSegmentsService.getDelegationMembersFilters(
                eventId,
                customFields,
                data.event,
                data.event.delegation.form
            );
        } else {
            return [];
        }
    }, [eventId, customFields, data.event]);
    const possibleColumns = React.useMemo(() => {
        if (data.event) {
            return volunteersSegmentsService.getDelegationMembersPossibleColumns(
                customFields,
                data.event.delegation.form
            );
        } else {
            return [];
        }
    }, [customFields, data.event]);
    const defaultAccreditationsSlotsIds = React.useMemo(
        () =>
            (data.event?.delegation.accreditationsSlots ?? []).map(
                (das) => das.accreditationSlotId
            ),
        [data.event]
    );
    const showWishedAccreditationsEdit =
        (data.event?.delegation.numberOfDelegationsAccreditationsSlots ?? 0) > 0;

    return isLoading ? (
        <Loader />
    ) : (
        <FilterColumns
            columns={columns}
            columnsSearch={[]}
            columnsToIncludes={columnsToIncludes}
            eventId={eventId}
            filters={filters}
            getMassActionId={(row: VolunteerRegistrationFragment) => row.userInfo.id}
            limit={limit}
            loadDataList={executeVolunteersQuery}
            loadDataListParams={{
                delegationId,
                includeCanRegistrationStateUpdate: false,
                includeIsEditable: false
            }}
            loadRow={executeVolunteerQuery}
            loadRowParams={{ includeCanRegistrationStateUpdate: false, includeIsEditable: false }}
            organizationId={null}
            possibleColumns={possibleColumns}
            predicates={predicates}
            renderActions={(massActions, _totalCount, reload) => (
                <DelegationMembersActions
                    customFields={customFields}
                    massActions={massActions}
                    showWishedAccreditationsEdit={showWishedAccreditationsEdit}
                    onMassEditSuccess={reload}
                />
            )}
            rendering={VolunteersRendering.List}
            setColumns={setColumns}
            setLimit={setLimit}
            setPredicates={setPredicates}
            setSort={setSort}
            showEditColumns={true}
            showEditMode={props.isEventAdmin}
            showFilters={true}
            showMassActions={props.canEditMembers}
            showRowDropdown={true}
            showRowEdit={props.canEditMembers}
            showSearchColumns={false}
            sort={sort}
        >
            <RichTableList
                getEditPath={props.getEditPath}
                renderCell={(column, row: VolunteerRegistrationFragment, reload) => (
                    <VolunteerCells
                        accreditationsPanelPath={DelegationsPaths.DELEGATION_MEMBER_ACCREDITATIONS({
                            organizationId,
                            eventId,
                            delegationId,
                            userInfoId: row.userInfo.id
                        })}
                        availabilitiesPanelPath={DelegationsPaths.DELEGATION_MEMBER_AVAILABILITIES({
                            organizationId,
                            eventId,
                            delegationId,
                            userInfoId: row.userInfo.id
                        })}
                        canAccredit={props.canAccredit}
                        canAssign={false}
                        canChangeDelegation={false}
                        canClickMemberName={props.canClickMemberName}
                        column={column}
                        customField={slugToCustomField.get(column.slug)}
                        eventId={eventId}
                        positionsPanelPath={DelegationsPaths.DELEGATION_MEMBER_POSITIONS({
                            organizationId,
                            eventId,
                            delegationId,
                            userInfoId: row.userInfo.id
                        })}
                        reloadRow={reload}
                        row={row}
                        weezevent={null}
                    />
                )}
                renderRowDropdown={(member: VolunteerRegistrationFragment, reload) => (
                    <DelegationMemberRowDropdown
                        accreditationState={member.accreditationState}
                        allowedCustomDocuments={props.customBadges}
                        canAccredit={props.canAccredit}
                        canEditMembers={props.canEditMembers}
                        customDocuments={data.event?.customDocuments ?? []}
                        deleteFromEvent={props.deleteFromEvent}
                        formId={props.formId}
                        member={member.userInfo}
                        reload={reload}
                        showDeleteFromEvent={props.showDeleteFromEvent}
                        showDocumentsDownload={props.showDocumentsDownload}
                        volunteerRegistrationId={member.id}
                    />
                )}
                renderRowPanels={(row: VolunteerRegistrationFragment, reload) => (
                    <VolunteerPanels
                        accreditationsPanelPath={DelegationsPaths.DELEGATION_MEMBER_ACCREDITATIONS({
                            organizationId,
                            eventId,
                            delegationId,
                            userInfoId: row.userInfo.id
                        })}
                        availabilitiesPanelPath={DelegationsPaths.DELEGATION_MEMBER_AVAILABILITIES({
                            organizationId,
                            eventId,
                            delegationId,
                            userInfoId: row.userInfo.id
                        })}
                        canAccredit={props.canAccredit}
                        canCheckIn={false}
                        canDeletePosition={() => false}
                        canEditWishedAccreditations={true}
                        defaultAccreditationsSlotsIds={defaultAccreditationsSlotsIds}
                        delegationId={delegationId}
                        eventId={eventId}
                        organizationId={organizationId}
                        positionsPanelPath={DelegationsPaths.DELEGATION_MEMBER_POSITIONS({
                            organizationId,
                            eventId,
                            delegationId,
                            userInfoId: row.userInfo.id
                        })}
                        reloadRow={reload}
                        returnPathFallback={DelegationsPaths.DELEGATION_MEMBERS({
                            organizationId,
                            eventId,
                            delegationId
                        })}
                        volunteerRegistration={row}
                    />
                )}
            />
        </FilterColumns>
    );
};
