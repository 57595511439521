import { Page } from 'common-front/src/components/page/page';
import { SearchRow } from 'common-front/src/segments/searchRow';
import { SegmentTableHeader } from 'common-front/src/segments/segmentTableHeader';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { CommunityCampaignsHeader } from './communityCampaignsHeader';
import { CommunityCampaignsList } from './communityCampaignsList';

export const CommunityCampaignsContent = () => {
    const translate = useTranslate();

    return (
        <>
            <CommunityCampaignsHeader />

            <Page.Content>
                <SegmentTableHeader
                    searchRow={
                        <SearchRow
                            searchPlaceholder={translate('rechercher_une_69554')}
                            showEditColumns={false}
                            showEditMode={false}
                            showSearchColumns={false}
                        />
                    }
                />

                <CommunityCampaignsList />
            </Page.Content>
        </>
    );
};
