import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { FiltersColumnsFilterPredicatesBadges } from './filtersColumnsFilterPredicatesBadges';
import { FiltersColumnsSearchRow } from './filtersColumnsSearchRow';

export const FiltersColumnsHeader = () => (
    <Flex
        css={{
            border: '1px solid $gray200',
            borderBottom: 'none',
            borderTopLeftRadius: '$1',
            borderTopRightRadius: '$1',
            padding: '$3 $4'
        }}
        direction="column"
        gap="4"
        width={1}
    >
        <FiltersColumnsSearchRow />

        <FiltersColumnsFilterPredicatesBadges />
    </Flex>
);
