import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import * as React from 'react';
import { Page } from '../components/page/page';
import { Content } from '../designSystem/components/tooltip/content';
import { Tooltip } from '../designSystem/components/tooltip/tooltip';
import { Trigger } from '../designSystem/components/tooltip/trigger';
import { useHeavent } from '../hooks/useHeavent';
import { useSegmentsContext } from './segmentsContext';

interface ISegmentHeaderProps {
    children: React.ReactNode;
    name?: React.ReactNode;
}

export const SegmentHeader = (props: ISegmentHeaderProps) => {
    const { translate } = useHeavent();
    const { areSegmentsOpen, hideSegments, segment, setAreSegmentsOpen } = useSegmentsContext();

    return (
        <Page.Header>
            <Flex align="center" gap="4" width={1}>
                {!hideSegments && (
                    <Flex
                        css={{ cursor: 'pointer', padding: '$2' }}
                        onClick={() => setAreSegmentsOpen(!areSegmentsOpen)}
                    >
                        <Tooltip>
                            <Trigger>
                                <Box>
                                    <I icon="sidebar" />
                                </Box>
                            </Trigger>

                            <Content placement="top">
                                {areSegmentsOpen
                                    ? translate('masquer_la_list_94215')
                                    : translate('afficher_la_lis_48771')}
                            </Content>
                        </Tooltip>
                    </Flex>
                )}

                {props.name || (
                    <Box css={{ flex: '1' }} font="gray900 displayXs medium">
                        {segment.name}
                    </Box>
                )}

                {props.children}
            </Flex>
        </Page.Header>
    );
};
