import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { FilterPredicate } from 'common/src/vo/segment';
import { Operator, Predicate } from 'common/src/vo/segments/predicate';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { DateInput } from '../../designSystem/components/date/dateInput';
import { RadioText } from '../../designSystem/components/radio';
import { DayMonthYearSelector } from './date/dayMonthYearSelector';

interface IAddDateFilterProps {
    filterPredicate: FilterPredicate;

    onAdd(predicate: Predicate): void;
}

export const AddDateFilter = (props: IAddDateFilterProps) => {
    const translate = useTranslate();
    const [operator, setOperator] = React.useState<Operator>(
        props.filterPredicate.predicate?.operator ?? 'is'
    );
    const [value, setValue] = React.useState<DateTime | string>(
        props.filterPredicate.predicate?.value ?? ''
    );
    const [value2, setValue2] = React.useState<DateTime | string>(
        props.filterPredicate.predicate?.value2 ?? ''
    );
    const [valueDate, setValueDate] = React.useState<any>(
        props.filterPredicate.predicate?.valueDate ?? {
            day: -1,
            month: -1,
            year: -1
        }
    );

    const valid = ['is', 'isNot'].includes(operator)
        ? (typeof valueDate.day === 'number' && valueDate.day !== -1) ||
          (typeof valueDate.month === 'number' && valueDate.month !== -1) ||
          (typeof valueDate.year === 'number' && valueDate.year !== -1)
        : ['isBefore', 'isAfter'].includes(operator)
          ? DateTime.isDateTime(value) && value.isValid
          : operator === 'between'
            ? DateTime.isDateTime(value) &&
              value.isValid &&
              DateTime.isDateTime(value2) &&
              value2.isValid
            : true;

    return (
        <>
            <RadioText
                state={operator === 'is' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('is');
                }}
            >
                {translate('est_25967')}
            </RadioText>

            {operator === 'is' && (
                <DayMonthYearSelector setValue={setValueDate} value={valueDate} />
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'isNot' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('isNot');
                }}
            >
                {translate('n_est_pas_97826')}
            </RadioText>

            {operator === 'isNot' && (
                <DayMonthYearSelector setValue={setValueDate} value={valueDate} />
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'isBefore' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('isBefore');
                }}
            >
                {translate('est_avant_le_16689')}
            </RadioText>

            {operator === 'isBefore' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DateInput
                            max={props.filterPredicate.filter.endAt}
                            min={props.filterPredicate.filter.startAt}
                            value={value}
                            onChange={setValue}
                        />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'isAfter' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('isAfter');
                }}
            >
                {translate('est_apr_s_le_79703')}
            </RadioText>

            {operator === 'isAfter' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DateInput
                            max={props.filterPredicate.filter.endAt}
                            min={props.filterPredicate.filter.startAt}
                            value={value}
                            onChange={setValue}
                        />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'between' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('between');
                }}
            >
                {translate('est_entre_born_59665')}
            </RadioText>

            {operator === 'between' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DateInput
                            max={props.filterPredicate.filter.endAt}
                            min={props.filterPredicate.filter.startAt}
                            value={value}
                            onChange={setValue}
                        />
                        <Spacer width="3" />
                        <DateInput
                            max={props.filterPredicate.filter.endAt}
                            min={props.filterPredicate.filter.startAt}
                            value={value2}
                            onChange={setValue2}
                        />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            {props.filterPredicate.filter.hideEmpty !== true && (
                <>
                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isEmpty' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isEmpty');
                        }}
                    >
                        {translate('est_vide_96759')}
                    </RadioText>

                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isNotEmpty' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isNotEmpty');
                        }}
                    >
                        {translate('n_est_pas_vide_45804')}
                    </RadioText>
                </>
            )}

            <Spacer height="7" />

            <Flex>
                <Button
                    disabled={!valid}
                    onClick={() => {
                        props.onAdd({
                            operator,
                            value,
                            value2,
                            valueDate,
                            slug: props.filterPredicate.filter.slug
                        });
                    }}
                >
                    {translate('ajouter_le_filt_54222')}
                </Button>
            </Flex>
        </>
    );
};
