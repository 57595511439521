import { Label } from 'common-front/src/designSystem/components/input/label';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePromise } from 'common-front/src/hooks/usePromise';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import * as React from 'react';
import { useEventsContext } from '../../eventsContext';

interface IEventsTagsCellInputProps {
    value: number[];

    setValue(value: number[]): void;
}

export const EventsTagsCellInput = (props: IEventsTagsCellInputProps) => {
    const { translate } = useHeavent();
    const { getTags } = useEventsContext();
    const { result: tags, isLoading } = usePromise(getTags);

    return isLoading ? (
        <Flex direction="column" gap="1" width={1}>
            <Label>{translate('tags_79499')}</Label>
            <Skeleton borderRadius="$1" height={38} width={1} />
        </Flex>
    ) : (
        <RichSelect
            isSearchVisible={true}
            label={translate('tags_79499')}
            multiple={true}
            renderOnPortal={true}
            values={props.value}
            onChange={props.setValue}
        >
            {tags.map((tag) => (
                <option key={tag.id} value={tag.id}>
                    {tag.name}
                </option>
            ))}
        </RichSelect>
    );
};
