import { UpdateCustomFieldCell } from 'common-front/src/components/cells/updateCustomFieldCell';
import { useEventsMassEditMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    CustomFieldSlug,
    EventsEventFragment,
    MassAssignStrategy,
    OrganizationId,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { getStateColor } from 'common/src/vo/event';
import { PossibleColumn } from 'common/src/vo/segment';
import { EventDefaultColumns } from 'common/src/vo/segments/eventsSegmentsService';
import * as React from 'react';
import { EventsEndAtCell } from './cells/eventsEndAtCell';
import { EventsNameCell } from './cells/eventsNameCell';
import { EventsStartAtCell } from './cells/eventsStartAtCell';
import { EventsTagsCell } from './cells/eventsTagsCell';

interface IEventCellsProps {
    column: PossibleColumn;
    customField: Emptyable<SegmentCustomFieldFragment>;
    organizationId: OrganizationId;
    row: EventsEventFragment;

    reloadRow(): void;
}

export const EventCells = (props: IEventCellsProps) => {
    const { translate } = useHeavent();
    const { mutate: massEdit } = useEventsMassEditMutation();
    const updateField = React.useCallback(
        (value: any, slug: CustomFieldSlug) =>
            massEdit({
                organizationId: props.organizationId,
                massEdit: {
                    reset: false,
                    selecteds: { ids: [props.row.id] },
                    slug,
                    strategy: MassAssignStrategy.Replace,
                    value
                }
            }),
        [props.row, massEdit]
    );

    return props.column.slug === EventDefaultColumns.Id ? (
        <Cell width={100}>{props.row.id}</Cell>
    ) : props.column.slug === EventDefaultColumns.Name ? (
        <EventsNameCell event={props.row} reload={props.reloadRow} />
    ) : props.column.slug === EventDefaultColumns.StartAt ? (
        <EventsStartAtCell event={props.row} reload={props.reloadRow} />
    ) : props.column.slug === EventDefaultColumns.EndAt ? (
        <EventsEndAtCell event={props.row} reload={props.reloadRow} />
    ) : props.column.slug === EventDefaultColumns.Tags ? (
        <EventsTagsCell event={props.row} reload={props.reloadRow} />
    ) : props.column.slug === EventDefaultColumns.State ? (
        <Cell>
            <Badge color={getStateColor(props.row.state)}>{translate(props.row.state)}</Badge>
        </Cell>
    ) : (
        <UpdateCustomFieldCell
            customField={props.customField}
            fields={props.row.fields}
            mutate={updateField}
            reload={props.reloadRow}
            updatable={true}
        />
    );
};
