import { Button } from 'common-front/src/designSystem/components/button';
import { Modal } from 'common-front/src/designSystem/components/modal/modal';
import { ToggleText } from 'common-front/src/designSystem/components/toggle';
import { CheckboxText } from 'common-front/src/designSystem/form/checkbox';
import { DateInput } from 'common-front/src/designSystem/form/date/dateInput';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { EventsEventFragment } from 'common/src/generated/types';
import {
    EventDuplicateInputService,
    IEventDuplicateValues
} from 'common/src/input/eventDuplicateInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isEqual } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useEventDuplicateMutation } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';

interface IEventDuplicatePopupProps {
    event: EventsEventFragment;

    onClose(): void;
    reload(): void;
}

export const EventDuplicateModal = (props: IEventDuplicatePopupProps) => {
    const translate = useTranslate();
    const eventDuplicateInput = useService(EventDuplicateInputService);
    const validateService = useService(ValidateService);
    const { mutate, isLoading } = useEventDuplicateMutation();
    const { organization, reload } = useOrganizationContext();
    const [isExistingEvent, setIsExistingEvent] = React.useState(false);
    const events = React.useMemo(
        () => organization.events.nodes.filter((e) => e.id !== props.event.id),
        [organization.events.nodes]
    );
    const diff = props.event.endAt.diff(props.event.startAt, 'days').days;

    return (
        <Form
            initialValues={{
                options: eventDuplicateInput.eventDuplicateInputDefault(props.event)
            }}
            initialValuesEqual={isEqual}
            render={({ form, handleSubmit, invalid, values }) => (
                <>
                    <OnChange name="options.startAt">
                        {(startAt: DateTime) => {
                            const newEndAt = startAt.plus({ days: diff });

                            if (!newEndAt.equals(values.options.endAt)) {
                                form.change('options.endAt', newEndAt);
                            }
                        }}
                    </OnChange>

                    <OnChange name="options.endAt">
                        {(endAt: DateTime) => {
                            const newStartAt = endAt.minus({ days: diff });

                            if (!newStartAt.equals(values.options.startAt)) {
                                form.change('options.startAt', newStartAt);
                            }
                        }}
                    </OnChange>

                    <Modal.Header icon="clone">
                        {translate('dupliquer_l_v_72541', props.event.name)}
                    </Modal.Header>

                    <Modal.Content css={{ overflowY: 'auto' }} gap="5">
                        <ToggleText
                            value={isExistingEvent}
                            onChange={(newValue) => {
                                setIsExistingEvent(newValue);

                                if (!newValue) {
                                    form.change('options.eventId', null);
                                }
                            }}
                        >
                            {translate('dupliquer_les_d_77458')}
                        </ToggleText>

                        {isExistingEvent ? (
                            <RichSelect
                                isSearchVisible={true}
                                label={translate('_v_nement_22345')}
                                name="options.eventId"
                            >
                                {events.map((event) => (
                                    <option key={event.id} value={event.id}>
                                        {event.name}
                                    </option>
                                ))}
                            </RichSelect>
                        ) : (
                            <>
                                <TextInput
                                    label={translate('nom_du_nouvel_66511')}
                                    name="options.name"
                                />

                                <Flex gap="5">
                                    <DateInput
                                        label={translate('nouvelle_date_d_75723')}
                                        min={DateTime.utc()}
                                        name="options.startAt"
                                    />

                                    <DateInput
                                        label={translate('nouvelle_date_d_93958')}
                                        min={DateTime.utc()}
                                        name="options.endAt"
                                    />
                                </Flex>
                            </>
                        )}

                        <Flex direction="column" gap="2">
                            <Box font="gray700 textSm medium">
                                {translate('que_souhaitez_v_40297')}
                            </Box>

                            <Flex direction="column" gap="3">
                                <Flex gap="5">
                                    <Box width={1}>
                                        <CheckboxText name="options.includePositions">
                                            {translate('missions_63972')}
                                        </CheckboxText>
                                    </Box>

                                    <Box width={1}>
                                        <CheckboxText name="options.includeAccreditations">
                                            {translate('accr_ditations_39450')}
                                        </CheckboxText>
                                    </Box>
                                </Flex>

                                <Flex gap="5">
                                    <Box width={1}>
                                        <CheckboxText name="options.includeForms">
                                            {translate('Form_plural')}
                                        </CheckboxText>
                                    </Box>

                                    <Box width={1}>
                                        <CheckboxText name="options.includeDelegations">
                                            {translate('d_l_gations_78318')}
                                        </CheckboxText>
                                    </Box>
                                </Flex>

                                <Flex gap="5">
                                    <Box width={1}>
                                        <CheckboxText name="options.includeSegments">
                                            {translate('vues_personnali_80652')}
                                        </CheckboxText>
                                    </Box>

                                    <Box width={1}>
                                        <CheckboxText name="options.includeCustomDocuments">
                                            {translate('documents_perso_87374')}
                                        </CheckboxText>
                                    </Box>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Modal.Content>

                    <Modal.Buttons>
                        <Button disabled={invalid} isLoading={isLoading} onClick={handleSubmit}>
                            {translate('dupliquer_28256')}
                        </Button>
                    </Modal.Buttons>
                </>
            )}
            validate={(values: IEventDuplicateValues) =>
                validateService.validateForForm(eventDuplicateInput.eventDuplicateSchema())(
                    values.options
                )
            }
            onSubmit={async (values: IEventDuplicateValues) => {
                await mutate({
                    eventId: props.event.id,
                    options: values.options
                });

                reload();
                props.reload();
                props.onClose();
            }}
        />
    );
};
