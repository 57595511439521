import { Blank } from 'common-front/src/components/blank/blank';
import { Page } from 'common-front/src/components/page/page';
import { SelectSegment } from 'common-front/src/segments/massActions/selectSegment';
import { SearchRow } from 'common-front/src/segments/searchRow';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { SegmentTableHeader } from 'common-front/src/segments/segmentTableHeader';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { SegmentCustomFieldFragment } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { AccreditationsRendering } from 'common/src/vo/rendering';
import * as React from 'react';
import { AccreditationsSlotsList } from '../../accreditationsSlots/list/accreditationsSlotsList';
import { AccreditationsActions } from './accreditationsActions';
import { AccreditationsHeader } from './accreditationsHeader';
import { AccreditationsList } from './accreditationsList';

interface IAccreditationsContentProps {
    customFields: SegmentCustomFieldFragment[];
    numberOfAccreditations: number;
    weezeventOrganizationId: Emptyable<number>;
}

export const AccreditationsContent = (props: IAccreditationsContentProps) => {
    const translate = useTranslate();
    const { limit, reloadKey, rendering, segment, setReloadKey } = useSegmentsContext();

    return (
        <>
            <AccreditationsHeader
                reload={() => {
                    setReloadKey(reloadKey + 1);
                }}
            />

            {props.numberOfAccreditations === 0 ? (
                <Page.Content css={{ overflow: 'hidden' }}>
                    <Box css={{ flex: '1', overflow: 'hidden' }}>
                        <Blank
                            imageSrc={Svgs.AccreditationsBlank}
                            subtitle=""
                            title={translate('vous_n_avez_pas_53408')}
                        />
                    </Box>
                </Page.Content>
            ) : (
                <Page.Content>
                    <SegmentTableHeader
                        searchRow={
                            <SearchRow
                                actions={<AccreditationsActions />}
                                searchPlaceholder={translate('rechercher_une_65646')}
                                showEditColumns={true}
                                showEditMode={true}
                                showSearchColumns={true}
                            />
                        }
                        selectSegment={
                            <SelectSegment
                                pageSelectedText={translate('les_1_accr_di_60244', limit)}
                                segmentSelectedText={translate(
                                    'les_1_accr_di_20650',
                                    segment.count,
                                    segment.name
                                )}
                            />
                        }
                    />

                    {rendering === AccreditationsRendering.SlotsList ? (
                        <AccreditationsSlotsList
                            customFields={props.customFields}
                            weezeventOrganizationId={props.weezeventOrganizationId}
                        />
                    ) : (
                        <AccreditationsList customFields={props.customFields} />
                    )}
                </Page.Content>
            )}
        </>
    );
};
