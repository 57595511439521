import { RoleType } from 'common/src/generated/types';
import { injectable } from 'inversify';

@injectable()
export class BaseRolesService {
    get() {
        return {
            [RoleType.OrganizationAdmin]: {
                positionPermissions: '11111111',
                nameKey: 'administrateur_11083',
                descriptionKey: 'a_acc_s_toute_73520'
            },
            [RoleType.OrganizationReader]: {
                positionPermissions: '10001000',
                nameKey: 'administrateur_44125',
                descriptionKey: 'a_acc_s_l_org_94388'
            },
            [RoleType.EventAdmin]: {
                positionPermissions: '11111111',
                nameKey: 'administrateur_24785',
                descriptionKey: 'a_acc_s_toute_25473'
            },
            [RoleType.EventReader]: {
                positionPermissions: '10001000',
                nameKey: 'administrateur_85472',
                descriptionKey: 'a_acc_s_l_v_17476'
            },
            [RoleType.PositionEditor]: {
                positionPermissions: '11001001',
                nameKey: 'responsable_di_43071',
                descriptionKey: 'peut_modifier_s_82832'
            },
            [RoleType.PositionReader]: {
                positionPermissions: '10001000',
                nameKey: 'responsable_lec_08535',
                descriptionKey: 'a_acc_s_ses_m_09741'
            }
        };
    }
}
