import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { EventsEventFragment, EventState } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { EventProperties } from '../../events/list/eventProperties';

export const EventRadioContentMobile = ({ event }: { event: EventsEventFragment }) => {
    const translate = useTranslate();

    return (
        <Flex align="start" direction="column" gap="2">
            <Badge color={event.state === EventState.Ongoing ? 'success' : 'error'}>
                {translate(event.state)}
            </Badge>
            <Box color="gray800" fontSize="textLg" fontWeight="medium" width={1}>
                {event.name}
            </Box>
            <EventProperties event={event} />
        </Flex>
    );
};
