import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { addOrRemove, isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { renderingIcon, renderingTranslation } from 'common/src/vo/rendering';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Button } from '../designSystem/components/button';
import { CheckboxText } from '../designSystem/components/checkbox';
import { Dropdown } from '../designSystem/components/dropdown/dropdown';
import { ItemIcon } from '../designSystem/components/dropdown/item';
import { Menu } from '../designSystem/components/dropdown/menu';
import { Trigger } from '../designSystem/components/dropdown/trigger';
import { TextInput } from '../designSystem/components/textInput';
import { ToggleText } from '../designSystem/components/toggle';
import { useSegmentsContext } from './segmentsContext';

interface ISearchRowProps {
    actions?: React.ReactNode;
    searchPlaceholder: string;
    showEditColumns: boolean;
    showEditMode: boolean;
    showSearchColumns: boolean;
}

export const SearchRow = (props: ISearchRowProps) => {
    const translate = useTranslate();
    const {
        columnsSearch,
        isAdmin,
        isEditMode,
        isSaveVisible,
        massActions,
        name,
        possibleColumns,
        possibleRenderings,
        rendering,
        setAreColumnsOpen,
        setIsEditMode,
        setIsFilterOpen,
        setColumnsSearch,
        setIsSaveSegmentVisible,
        setName,
        setNameDebounced,
        setRendering,
        setSelectedFilterPredicate
    } = useSegmentsContext();
    const showActions = props.actions && isAdmin && massActions.numberOfSelected > 0;
    const searchableColumns = React.useMemo(
        () =>
            sortBy(
                possibleColumns.filter((c) => c.isSearchable),
                (c) => c.name
            ),
        [possibleColumns]
    );
    const searchInputCss = React.useMemo(
        () =>
            props.showSearchColumns
                ? {
                      '& .h-styled-input-container': {
                          borderBottomRightRadius: '0',
                          borderTopRightRadius: '0'
                      }
                  }
                : {},
        [props.showSearchColumns]
    );

    return (
        <Flex align="center" gap="4">
            <Flex width={320}>
                <TextInput
                    css={searchInputCss}
                    icon="magnifying-glass"
                    placeholder={props.searchPlaceholder}
                    value={name}
                    onChange={(newName: string) => {
                        setName(newName);
                        setNameDebounced(newName);
                    }}
                />

                {props.showSearchColumns && (
                    <Dropdown>
                        <Trigger>
                            <Button
                                color="white"
                                css={{
                                    borderLeft: 'none',
                                    borderBottomLeftRadius: '0',
                                    borderTopLeftRadius: '0'
                                }}
                                leftIcon="bars-filter"
                            />
                        </Trigger>

                        <Menu
                            css={{ padding: '0' }}
                            maxHeight={350}
                            placement="bottom-end"
                            width={320}
                        >
                            <Flex css={{ padding: '$4 $6' }} direction="column" gap="4">
                                <Box font="gray800 textMd medium">
                                    {translate('rechercher_sur_85243')}
                                </Box>

                                <Flex direction="column" gap="3">
                                    {searchableColumns.map(({ slug, name }) => (
                                        <CheckboxText
                                            key={slug}
                                            state={
                                                columnsSearch.includes(slug)
                                                    ? 'checked'
                                                    : 'unchecked'
                                            }
                                            onClick={(state) => {
                                                setColumnsSearch(
                                                    addOrRemove(
                                                        columnsSearch,
                                                        slug,
                                                        state === 'checked'
                                                    )
                                                );
                                            }}
                                        >
                                            {name}
                                        </CheckboxText>
                                    ))}
                                </Flex>
                            </Flex>
                        </Menu>
                    </Dropdown>
                )}
            </Flex>

            <Button
                color="gray"
                leftIcon="bars-filter"
                onClick={() => {
                    setSelectedFilterPredicate(null);
                    setIsFilterOpen(true);
                }}
            >
                {translate('filtres_64574')}
            </Button>

            <Box css={{ flex: '1' }}>
                {isAdmin && isSaveVisible && (
                    <Button
                        color="invisible"
                        onClick={() => {
                            setIsSaveSegmentVisible(true);
                        }}
                    >
                        {translate('enregistrer_la_22884')}
                    </Button>
                )}
            </Box>

            {!showActions && props.showEditColumns && (
                <Button
                    color="invisible"
                    css={{ color: '$gray800', '&:hover': { color: '$gray800' } }}
                    leftIcon="gear"
                    onClick={() => {
                        setAreColumnsOpen(true);
                    }}
                >
                    {translate('_diter_les_colo_39630')}
                </Button>
            )}

            {!showActions && props.showEditMode && (
                <ToggleText value={isEditMode} onChange={setIsEditMode}>
                    {translate('mode_dition_93778')}
                </ToggleText>
            )}

            {!showActions && isNonEmptyArray(possibleRenderings) && (
                <Dropdown>
                    <Trigger>
                        <Button color="white" leftIcon={renderingIcon(rendering)}>
                            {renderingTranslation(translate, rendering)}
                        </Button>
                    </Trigger>

                    <Menu placement="bottom-end">
                        {possibleRenderings.map((possibleRendering) => (
                            <ItemIcon
                                key={possibleRendering}
                                icon={renderingIcon(possibleRendering)}
                                onClick={() => setRendering(possibleRendering)}
                            >
                                {renderingTranslation(translate, possibleRendering)}
                            </ItemIcon>
                        ))}
                    </Menu>
                </Dropdown>
            )}

            {showActions && props.actions}
        </Flex>
    );
};
