import { UpdateLinkTextCell } from 'common-front/src/components/cells/updateLinkTextCell';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { LinkText } from 'common/src/designSystem/components/linkText';
import { EventProperty, EventsEventFragment } from 'common/src/generated/types';
import { EventsPaths } from 'common/src/util/paths/eventsPaths';
import * as React from 'react';
import { EventsUpdateCell } from './eventsUpdateCell';

interface IEventsNameCellProps {
    event: EventsEventFragment;

    reload(): void;
}

export const EventsNameCell = (props: IEventsNameCellProps) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const { isEditMode } = useSegmentsContext();

    return (
        <EventsUpdateCell
            eventId={props.event.id}
            initialValue={props.event.name}
            property={EventProperty.Name}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput
                    label={translate('nom_de_l_v_nem_08711')}
                    value={value}
                    onChange={setValue}
                />
            )}
            renderValue={() => (
                <UpdateLinkTextCell
                    isEditMode={isEditMode}
                    title={props.event.name}
                    updatable={true}
                >
                    <LinkText
                        to={EventsPaths.EVENT({
                            organizationId,
                            eventId: props.event.id
                        })}
                    >
                        {props.event.name}
                    </LinkText>
                </UpdateLinkTextCell>
            )}
            updatable={true}
        />
    );
};
