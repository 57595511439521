import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CustomFieldSlug, UsersInfo } from 'common/src/generated/types';
import * as React from 'react';
import { TextInput } from '../../designSystem/components/textInput';
import { useFiltersColumnsContext } from '../../filtersColumns/filtersColumnsContext';
import { useHeavent } from '../../hooks/useHeavent';
import { useSegmentsContext } from '../../segments/segmentsContext';
import { AvatarNameEmail } from '../avatarNameEmail';
import { UpdateCellDropdown } from './updateCellDropdown';

interface IValues {
    firstName: string;
    lastName: string;
}

interface IUpdateNameCellProps {
    canClickMemberName: boolean;
    updatable: boolean;
    userInfo: Pick<UsersInfo, 'id' | 'email' | 'fields' | 'name'> & { picture?: any };

    mutate(value: any, slug: CustomFieldSlug): Promise<any>;
    reload(): void;
}

export const UpdateNameCell = (props: IUpdateNameCellProps) => {
    const { translate } = useHeavent();
    // TODO: temporary solution
    // TODO: use only useFiltersColumnsContext() once refacto is done
    const { isEditMode: segmentsIsEditMode } = useSegmentsContext();
    const { isEditMode: richTableIsEditMode } = useFiltersColumnsContext();
    const isEditMode = segmentsIsEditMode || richTableIsEditMode;
    const initialValue = React.useMemo(
        () => ({
            firstName: props.userInfo.fields.firstName || '',
            lastName: props.userInfo.fields.lastName || ''
        }),
        [props.userInfo]
    );
    const onSave = React.useCallback(
        async (values: IValues) => {
            await props.mutate(values.firstName, 'firstName' as CustomFieldSlug);
            await props.mutate(values.lastName, 'lastName' as CustomFieldSlug);

            props.reload();
        },
        [props.mutate, props.reload]
    );

    if (isEditMode && props.updatable) {
        return (
            <UpdateCellDropdown
                initialValue={initialValue}
                renderInput={(value: IValues, setValue) => (
                    <Flex direction="column" gap="2" width={1}>
                        <TextInput
                            label={translate('pr_nom_66988')}
                            value={value.firstName}
                            onChange={(newValue) => {
                                setValue({ ...value, firstName: newValue });
                            }}
                        />

                        <TextInput
                            label={translate('last_name')}
                            value={value.lastName}
                            onChange={(newValue) => {
                                setValue({ ...value, lastName: newValue });
                            }}
                        />
                    </Flex>
                )}
                renderValue={() => (
                    <AvatarNameEmail isClickDisabled={true} size={32} userInfo={props.userInfo} />
                )}
                onSave={onSave}
            />
        );
    } else {
        return (
            <Cell css={{ border: '2px solid transparent' }}>
                <AvatarNameEmail
                    isClickDisabled={!props.canClickMemberName}
                    size={32}
                    userInfo={props.userInfo}
                />
            </Cell>
        );
    }
};
