import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { useUserContext } from 'common-front/src/userContext';
import { LocalStorageKeys } from 'common-front/src/util/localStorage';
import { CommonEnvVars } from 'common/src/envVars';
import { useLocalStorage } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

export const Hubspot = () => {
    const { user } = useUserContext();
    const onMobile = useMobileQuery();
    const localStorage = useLocalStorage();

    React.useEffect(() => {
        if (CommonEnvVars.USE_HUBSPOT && isNonEmptyString(user?.hubspotToken)) {
            (window as any).hsConversationsSettings = {
                identificationEmail: user!.email,
                identificationToken: user!.hubspotToken
            };

            try {
                if (onMobile || localStorage.getItem(LocalStorageKeys.HIDE_HUBSPOT) === 'true') {
                    (window as any).HubSpotConversations.widget.remove();
                } else {
                    (window as any).HubSpotConversations.clear({ resetWidget: true });
                }
            } catch {
                // Do nothing. In presence of ad-blockers, calls fail and would lead to a blank page.
            }
        }
    }, [onMobile]);

    return null;
};
