import { AvatarNameEmail } from 'common-front/src/components/avatarNameEmail';
import { Blank } from 'common-front/src/components/blank/blank';
import { Loader } from 'common-front/src/components/loader/loader';
import { Button } from 'common-front/src/designSystem/components/button';
import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { executeVolunteerQuery, useVolunteersQuery } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Images } from 'common-front/src/util/assets';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import {
    AccreditationsSlotId,
    VolunteerRegistrationFragment,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isEmptyArray, isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { fullName } from 'common/src/vo/accreditationSlot';
import { columnsToIncludes } from 'common/src/vo/segments/volunteersSegmentsService';
import { intersection, sortBy } from 'lodash-es';
import * as React from 'react';
import { useVolunteersAccreditationsMatrixQuery } from '../../../generated/graphqlHooks';
import { useLocalEventState } from '../../../hooks/useLocalState';
import { VolunteersAccreditationsMatrixRow } from './volunteersAccreditationsMatrixRow';

export const VolunteersAccreditationsMatrix = () => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const matrixRef = React.useRef<HTMLDivElement | null>(null);
    const dateTimeService = useService(DateTimeService);
    const { limit, nameDebounced, offset, predicates, setLimit, setOffset, columns, rendering } =
        useSegmentsContext();
    const includes = React.useMemo(
        () => ({
            ...columnsToIncludes(columns, rendering),
            includeCanRegistrationStateUpdate: false,
            includeIsEditable: false
        }),
        [columns, rendering]
    );
    const { data: accreditationsData, isLoading: areAccreditationsLoading } =
        useVolunteersAccreditationsMatrixQuery({
            eventId
        });
    const { data, isLoading } = useVolunteersQuery({
        ...includes,
        eventId,
        predicates,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : null,
        limit,
        offset
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.data?.rows);
    const [vrs, setVrs] = React.useState<VolunteerRegistrationFragment[]>([]);
    const [idToLoading, setIdToLoading] = React.useState<Record<number, boolean>>({});
    const [selectedSlotsIds, setSelectedSlotsIds] = useLocalEventState<AccreditationsSlotId[]>(
        'volunteers.matrix.selectedSlotsIds',
        []
    );
    const allAccreditations = React.useMemo(
        () =>
            sortBy(accreditationsData.event?.accreditations.nodes ?? [], (a) => [
                a.accreditationCategory.name.toLowerCase(),
                a.name.toLowerCase()
            ]),
        [accreditationsData.event]
    );
    const accreditations = React.useMemo(
        () =>
            allAccreditations.flatMap((accreditation) => {
                const slotsIds = accreditation.slots.map((s) => s.id);
                const numberOfSlots = isEmptyArray(selectedSlotsIds)
                    ? accreditation.slots.length
                    : intersection(slotsIds, selectedSlotsIds).length;

                return numberOfSlots === 0
                    ? []
                    : [
                          {
                              ...accreditation,
                              numberOfSlots
                          }
                      ];
            }),
        [allAccreditations, selectedSlotsIds]
    );
    const slots = React.useMemo(
        () =>
            accreditations.flatMap((accreditation) =>
                accreditation.slots.filter(
                    (slot) => isEmptyArray(selectedSlotsIds) || selectedSlotsIds.includes(slot.id)
                )
            ),
        [accreditations, selectedSlotsIds]
    );
    const slotsIds = React.useMemo(() => slots.map((s) => s.id), [slots]);
    const reloadRow = React.useCallback(
        async (id: VolunteersRegistrationId) => {
            setIdToLoading((ids) => ({ ...ids, [id]: true }));

            const {
                data: { row }
            } = await executeVolunteerQuery({ ...includes, eventId, id }, await getToken());

            setVrs((currentVrs) => currentVrs.map((vr) => (vr.id === id ? row : vr)));
            setIdToLoading((ids) => ({ ...ids, [id]: false }));
        },
        [includes, setIdToLoading, setVrs]
    );

    React.useEffect(() => {
        setVrs(data.data?.rows.nodes ?? []);
    }, [data.data]);

    return (
        <Flex direction="column" width={1}>
            {isLoading || areAccreditationsLoading ? (
                <Loader />
            ) : isEmptyArray(vrs) || isEmptyArray(accreditations) ? (
                <Flex
                    css={{
                        background: 'white',
                        border: '1px solid $gray200'
                    }}
                    height={400}
                >
                    {isEmptyArray(vrs) ? (
                        <Blank
                            imageSrc={Images.Blanks.Member}
                            title={translate('aucun_membre_ne_07054')}
                        />
                    ) : (
                        <Blank
                            imageSrc={Images.Blanks.Accreditation}
                            title={translate('aucune_accr_dit_97505')}
                        />
                    )}
                </Flex>
            ) : (
                <>
                    <Flex
                        ref={matrixRef}
                        css={{
                            background: 'white',
                            border: '1px solid $gray200'
                        }}
                    >
                        <Flex
                            css={{
                                width: `calc(2 * $cellWidth)`
                            }}
                            direction="column"
                        >
                            <Flex
                                align="center"
                                css={{
                                    borderBottom: '1px solid $gray200',
                                    borderRight: '1px solid $gray200',
                                    height: '$cellHeightSm',
                                    px: '$4'
                                }}
                                width={1}
                            >
                                <RichSelect
                                    isSearchVisible={true}
                                    isSelectAllVisible={true}
                                    menuWidth={300}
                                    multiple={true}
                                    triggerElem={
                                        <Button color="white" leftIcon="bars-filter" size="sm">
                                            Accréditations
                                            {isNonEmptyArray(selectedSlotsIds) &&
                                                ` (${selectedSlotsIds.length})`}
                                        </Button>
                                    }
                                    values={selectedSlotsIds}
                                    onChange={setSelectedSlotsIds}
                                >
                                    {allAccreditations.map((accreditation) => (
                                        <optgroup key={accreditation.id} label={accreditation.name}>
                                            {accreditation.slots.map((slot) => (
                                                <option key={slot.id} value={slot.id}>
                                                    {fullName(
                                                        dateTimeService,
                                                        slot,
                                                        accreditation.name,
                                                        {
                                                            accreditation: {
                                                                includeNameAtEnd: true
                                                            }
                                                        }
                                                    )}
                                                </option>
                                            ))}
                                        </optgroup>
                                    ))}
                                </RichSelect>
                            </Flex>

                            <Flex
                                css={{
                                    borderRight: '1px solid $gray200',
                                    height: '$cellHeightSm'
                                }}
                                width={1}
                                onClick={() => {
                                    matrixRef.current?.requestFullscreen();
                                }}
                            />

                            {vrs.map((vr) => (
                                <Flex
                                    key={vr.userInfo.id}
                                    css={{
                                        borderRight: '1px solid $gray200',
                                        borderTop: '1px solid $gray200',
                                        height: '$cellHeightSm',
                                        px: '$4'
                                    }}
                                    width={1}
                                >
                                    <AvatarNameEmail size={32} userInfo={vr.userInfo} />
                                </Flex>
                            ))}
                        </Flex>

                        <Flex css={{ flex: '1', overflowX: 'auto' }}>
                            <Flex direction="column">
                                <Flex
                                    css={{
                                        borderBottom: '1px solid $gray200',
                                        height: '$cellHeightSm'
                                    }}
                                >
                                    {accreditations.map((accreditation) => {
                                        const name = isNonEmptyString(accreditation.acronym)
                                            ? accreditation.acronym
                                            : accreditation.name;

                                        return (
                                            <Flex
                                                key={accreditation.id}
                                                align="center"
                                                css={{
                                                    borderRight: '1px solid $gray200',
                                                    px: '$4',
                                                    width: `calc(${accreditation.numberOfSlots} * $cellWidth)`
                                                }}
                                                direction="column"
                                                height={1}
                                                justify="center"
                                            >
                                                <Box
                                                    css={{ ellipsis: true }}
                                                    font="gray800 textSm medium"
                                                    textAlign="center"
                                                    title={name}
                                                    width={1}
                                                >
                                                    {name}
                                                </Box>

                                                <Box
                                                    color="gray500"
                                                    css={{ ellipsis: true }}
                                                    textAlign="center"
                                                    title={accreditation.accreditationCategory.name}
                                                    width={1}
                                                >
                                                    {accreditation.accreditationCategory.name}
                                                </Box>
                                            </Flex>
                                        );
                                    })}
                                </Flex>

                                <Flex css={{ height: '$cellHeightSm' }}>
                                    {slots.map((slot) => {
                                        const slotDate = slot.date?.isValid
                                            ? dateTimeService.toLocaleString(
                                                  slot.date,
                                                  LocaleFormats.DateOnly.Numeric
                                              )
                                            : '-';

                                        return (
                                            <Flex
                                                key={slot.id}
                                                align="center"
                                                css={{
                                                    borderRight: '1px solid $gray200',
                                                    px: '$4',
                                                    width: '$cellWidth'
                                                }}
                                                direction="column"
                                                height={1}
                                                justify="center"
                                            >
                                                <Box
                                                    css={{ ellipsis: true }}
                                                    font="gray800 textSm medium"
                                                    textAlign="center"
                                                    title={slot.name || '-'}
                                                    width={1}
                                                >
                                                    {slot.name || '-'}
                                                </Box>

                                                <Box
                                                    color="gray500"
                                                    css={{ ellipsis: true }}
                                                    textAlign="center"
                                                    title={slotDate}
                                                    width={1}
                                                >
                                                    {slotDate}
                                                </Box>
                                            </Flex>
                                        );
                                    })}
                                </Flex>

                                {vrs.map((vr) => {
                                    if (idToLoading[vr.id]) {
                                        return (
                                            <RowSkeleton
                                                key={vr.userInfo.id}
                                                css={{
                                                    border: 'none',
                                                    borderTop: '1px solid $gray200',
                                                    height: '$cellHeightSm'
                                                }}
                                            />
                                        );
                                    } else {
                                        return (
                                            <VolunteersAccreditationsMatrixRow
                                                key={vr.userInfo.id}
                                                reloadRow={reloadRow}
                                                slotsIds={slotsIds}
                                                vr={vr}
                                            />
                                        );
                                    }
                                })}
                            </Flex>
                        </Flex>
                    </Flex>

                    <PaginationRow>
                        <PaginationCell
                            limit={limit}
                            numberOfPages={numberOfPages}
                            offset={offset}
                            setLimit={setLimit}
                            setOffset={setOffset}
                            showLimits={true}
                            totalCount={totalCount}
                        />
                    </PaginationRow>
                </>
            )}
        </Flex>
    );
};
