import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentHeader } from 'common-front/src/segments/segmentHeader';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { useOrganizationContext } from '../../organization/organizationContext';

export const EventsHeader = () => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const { isOrganizationAdmin } = useOrganizationContext();

    return (
        <SegmentHeader>
            {isOrganizationAdmin && (
                <Button
                    onClick={() => {
                        history.push(HeaventPaths.CREATE_EVENT(organizationId));
                    }}
                >
                    {translate('cr_er_un_v_nem_28126')}
                </Button>
            )}
        </SegmentHeader>
    );
};
