import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentHeader } from 'common-front/src/segments/segmentHeader';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import * as React from 'react';
import { CreateAccreditationCategory } from '../../accreditationsCategories/create/createUpdateAccreditationCategory';

interface IAccreditationsHeaderProps {
    reload(): void;
}

export const AccreditationsHeader = (props: IAccreditationsHeaderProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [isCreateAccreditationCategoryOpen, setIsCreateAccreditationCategoryOpen] =
        React.useState(false);

    return (
        <SegmentHeader>
            <Button to={HeaventPaths.CREATE_ACCREDITATION(organizationId, eventId)}>
                {translate('cr_er_une_accr_33475')}
            </Button>

            <Dropdown isOpen={isDropdownOpen} onStateChange={setIsDropdownOpen}>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon
                        icon="plus"
                        onClick={() => {
                            setIsDropdownOpen(false);
                            setIsCreateAccreditationCategoryOpen(true);
                        }}
                    >
                        {translate('cr_er_une_cat_g_20051')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            {isCreateAccreditationCategoryOpen && (
                <CreateAccreditationCategory
                    eventId={eventId}
                    onClose={() => {
                        setIsCreateAccreditationCategoryOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}
        </SegmentHeader>
    );
};
