import { FormBox } from 'common-front/src/designSystem/components/formBox';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { FileS3Input } from 'common-front/src/designSystem/form/file/fileS3Input';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Accept } from 'common-front/src/util/accept';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { MAX_CSV_SIZE } from 'common/src/input/csvInput';
import { NumberService } from 'common/src/services/numberService';
import * as React from 'react';

interface ICSVFormBoxProps {
    file?: File;
    ids?: {
        descId: string;
        errorId: string;
        inputId: string;
        labelId: string;
    };

    // TODO - make required when implemented in positions import
    onChange?: (file: File) => void;
}

export const CSVFormBox = ({ file, ids, onChange }: ICSVFormBoxProps) => {
    const {
        params: { organizationId },
        translate
    } = useHeavent();

    return (
        <FormBox
            {...ids}
            hideToggle={true}
            initialIsOpen={true}
            subtitle={translate(
                's_lectionnez_vo_125161',
                NumberService.bytesToMegabytes(MAX_CSV_SIZE)
            )}
            title={translate('votre_fichier_d_51776')}
        >
            <FileS3Input
                {...ids}
                accept={Accept.Csv}
                acl="private"
                organizationId={organizationId}
                prefix="input.csv."
                withFileField={true}
                onChange={onChange}
            />

            <HorizontalSpacerSeparator height="5" />

            <Flex direction="column" gap="4">
                <Box font="gray900 textMd medium">{translate('param_tres_du_f_01675')}</Box>

                <Flex direction="column" gap="6">
                    <Box width={1}>
                        <TextInput
                            description={translate('you_must_set_th_73159')}
                            label={translate('set_delimiter_20565')}
                            name="input.csv.delimiter"
                            state={file ? 'disabled' : undefined}
                        />
                    </Box>

                    <Flex direction="row" gap="2">
                        <Box width={1}>
                            <TextInput
                                description={translate('leave_blank_if_02058')}
                                label={translate('set_quote_chara_43496')}
                                name="input.csv.quoteChar"
                                state={file ? 'disabled' : undefined}
                            />
                        </Box>

                        <Box width={1}>
                            <Select
                                description={translate('leave_blank_to_11865')}
                                label={translate('set_newline_cha_37738')}
                                name="input.csv.newline"
                                state={file ? 'disabled' : undefined}
                            >
                                <option value={undefined}>Auto-detect</option>
                                <option value={'\r'}>\r</option>
                                <option value={'\n'}>\n</option>
                                <option value={'\r\n'}>\r\n</option>
                            </Select>
                        </Box>
                    </Flex>

                    <Flex direction="row" gap="2">
                        <Box width={1}>
                            <TextInput
                                label={translate('format_d_heure_46798')}
                                name="input.csv.formats.timeFormat"
                                state={file ? 'disabled' : undefined}
                            />
                        </Box>

                        <Box width={1}>
                            <TextInput
                                label={translate('format_de_date_82059')}
                                name="input.csv.formats.dateFormat"
                                state={file ? 'disabled' : undefined}
                            />
                        </Box>

                        <Box width={1}>
                            <TextInput
                                label={translate('format_de_date_24474')}
                                name="input.csv.formats.datetimeFormat"
                                state={file ? 'disabled' : undefined}
                            />
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </FormBox>
    );
};
