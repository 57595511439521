import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDelegationCategoryQuery } from '../../generated/graphqlHooks';
import { DelegationCategory } from './delegationCategory';

interface IDelegationCategoryLoaderProps {
    isEvent: boolean;
}

export const DelegationCategoryLoader = (props: IDelegationCategoryLoaderProps) => {
    const {
        params: { organizationId, eventId, delegationCategoryId }
    } = useHeavent();
    const { data, loader, reload } = useDelegationCategoryQuery({
        organizationId,
        delegationCategoryId
    });

    return (
        loader || (
            <Switch>
                <Route
                    exact
                    path={DelegationsPaths.DELEGATION_CATEGORY({
                        organizationId: ':organizationId',
                        eventId: props.isEvent ? ':eventId' : undefined,
                        delegationCategoryId: ':delegationCategoryId'
                    })}
                >
                    <Empty
                        path={DelegationsPaths.DELEGATION_CATEGORY_DASHBOARD({
                            organizationId,
                            eventId,
                            delegationCategoryId
                        })}
                        replace={true}
                    />
                </Route>

                <Route>
                    <DelegationCategory organization={data.organization} reload={reload} />
                </Route>
            </Switch>
        )
    );
};
