import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CustomFieldSlug, SegmentCustomFieldFragment } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { preventDefault } from 'common/src/util/links';
import { Fields, FieldService } from 'common/src/vo/field';
import * as React from 'react';
import { Checkbox, ICheckboxState } from '../../designSystem/components/checkbox';
import { useFiltersColumnsContext } from '../../filtersColumns/filtersColumnsContext';
import { useSegmentsContext } from '../../segments/segmentsContext';

interface IUpdateCheckboxCellProps {
    customField: SegmentCustomFieldFragment;
    fields: Fields;
    updatable: boolean;

    mutate(value: boolean, slug: CustomFieldSlug): Promise<any>;
    reload(): void;
}

export const UpdateCheckboxCell = (props: IUpdateCheckboxCellProps) => {
    const fieldService = useService(FieldService);
    // TODO: temporary solution
    // TODO: use only useFiltersColumnsContext() once refacto is done
    const { isEditMode: segmentsIsEditMode } = useSegmentsContext();
    const { isEditMode: richTableIsEditMode } = useFiltersColumnsContext();
    const isEditMode = segmentsIsEditMode || richTableIsEditMode;
    const value = fieldService.getValue<boolean>(props.customField, props.fields);
    const valueString = fieldService.getValueString(props.customField, props.fields);
    const state = value === true ? 'checked' : value === false ? 'unchecked' : 'indeterminate';
    const onClick = async (newState: ICheckboxState) => {
        await props.mutate(newState === 'checked', props.customField.slug);

        props.reload();
    };

    if (isEditMode && props.updatable) {
        return (
            <Cell
                css={{
                    border: '2px solid transparent',
                    '& > .h-checkbox-cell-hover': {
                        display: 'none'
                    },
                    '&:hover': {
                        border: '2px solid $primary700'
                    },
                    '&:hover > .h-checkbox-cell-default': {
                        display: 'none'
                    },
                    '&:hover > .h-checkbox-cell-hover': {
                        display: 'block'
                    }
                }}
                onClick={preventDefault}
            >
                <Box className="h-checkbox-cell-default">{valueString}</Box>

                <Box className="h-checkbox-cell-hover">
                    <Checkbox state={state} onClick={onClick} />
                </Box>
            </Cell>
        );
    } else {
        return (
            <Cell
                css={{
                    border: '2px solid transparent'
                }}
            >
                {valueString}
            </Cell>
        );
    }
};
