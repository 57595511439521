import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { FiltersPredicatesBadges } from './filtersPredicates/filtersPredicatesBadges';
import { useSegmentsContext } from './segmentsContext';

interface ISegmentTableHeaderProps {
    searchRow: React.ReactNode;
    selectSegment?: React.ReactNode;
}

export const SegmentTableHeader = (props: ISegmentTableHeaderProps) => {
    const { isAdmin, limit, massActions } = useSegmentsContext();
    const showSelectSegment = isAdmin && massActions.numberOfSelected === limit;

    return (
        <Flex
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderBottom: 'none',
                borderTopLeftRadius: '$2',
                borderTopRightRadius: '$2',
                padding: '$3 $4'
            }}
            direction="column"
            gap="4"
            width={1}
        >
            {props.searchRow}

            <FiltersPredicatesBadges />

            {showSelectSegment && props.selectSegment}
        </Flex>
    );
};
