import { Page } from 'common-front/src/components/page/page';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useTitle } from 'common-front/src/hooks/useTitle';
import { AccreditationsCategoryQuery } from 'common/src/generated/types';
import {
    AccreditationCategoryParams,
    AccreditationsPaths
} from 'common/src/util/paths/accreditationsPaths';
import * as React from 'react';
import { AccreditationMembers } from '../../accreditations/show/members/accreditationMembers';
import { AccreditationsCategoryHeader } from './accreditationsCategoryHeader';
import { AccreditationCategoryDashboard } from './dashboard/accreditationCategoryDashboard';

interface IAccreditationsCategoryProps {
    event: AccreditationsCategoryQuery['event'];

    reload(): void;
}

export const AccreditationsCategory = ({ event, reload }: IAccreditationsCategoryProps) => {
    const {
        params: { organizationId, eventId, accreditationsCategoryId },
        translate
    } = useHeavent();
    useTitle(event.accreditationCategory.name);

    const getAccreditationParams = (isRouteComponent: boolean): AccreditationCategoryParams => ({
        organizationId: isRouteComponent ? ':organizationId' : organizationId,
        eventId: isRouteComponent ? ':eventId' : eventId,
        accreditationCategoryId: isRouteComponent
            ? ':accreditationsCategoryId'
            : accreditationsCategoryId
    });

    return (
        <Page.Root>
            <AccreditationsCategoryHeader category={event.accreditationCategory} reload={reload} />

            <Tabs css={{ flex: '1' }}>
                <TabList css={{ px: '$7' }}>
                    <Tab
                        path={AccreditationsPaths.ACCREDITATION_CATEGORY_DASHBOARD(
                            getAccreditationParams(false)
                        )}
                    >
                        {translate('tableau_de_bord_24627')}
                    </Tab>

                    <Tab
                        path={AccreditationsPaths.ACCREDITATION_CATEGORY_MEMBERS(
                            getAccreditationParams(false)
                        )}
                    >
                        {translate('membres_125561', event.accreditationCategory.assignedResources)}
                    </Tab>
                </TabList>

                <TabPanel
                    path={AccreditationsPaths.ACCREDITATION_CATEGORY_DASHBOARD(
                        getAccreditationParams(true)
                    )}
                >
                    <AccreditationCategoryDashboard />
                </TabPanel>

                <TabPanel
                    path={AccreditationsPaths.ACCREDITATION_CATEGORY_MEMBERS(
                        getAccreditationParams(true)
                    )}
                >
                    <AccreditationMembers accreditationCategoryId={accreditationsCategoryId} />
                </TabPanel>
            </Tabs>
        </Page.Root>
    );
};
