import { Emptyable } from 'common/src/util/emptyable';
import { NominalType } from 'common/src/util/nominalType';
import { AllRendering } from 'common/src/vo/rendering';
import { Columns, FilterPredicate, PossibleColumn } from 'common/src/vo/segment';
import { Predicates } from 'common/src/vo/segments/predicate';
import { Sort } from 'common/src/vo/sort';
import React from 'react';
import { IUseMassActionsResult } from '../hooks/useMassActions';
import { LoadDataResultRows } from './filtersColumnsTypes';

interface IFiltersColumnsContext<
    T extends { id: TId },
    TId extends NominalType<number, any>,
    SortAttribute
> {
    areColumnsOpen: boolean;
    columns: Columns;
    columnsSearch: Columns;
    data: T[];
    filtersPredicates: FilterPredicate[];
    idToLoading: Record<TId, boolean>;
    isEditMode: boolean;
    isFilterOpen: boolean;
    isLoading: boolean;
    isSaveVisible: boolean;
    limit: number;
    loadDataResult: Emptyable<LoadDataResultRows<T>>;
    massActions: IUseMassActionsResult<TId>;
    name: string;
    nameDebounced: string;
    numberOfPages: number;
    offset: number;
    possibleColumns: PossibleColumn[];
    possibleRenderings?: AllRendering[];
    predicates: Predicates;
    rendering: AllRendering;
    selectedFilterPredicate: FilterPredicate | null;
    showEditColumns: boolean;
    showEditMode: boolean;
    showFilters: boolean;
    showMassActions: boolean;
    showRowDropdown: boolean;
    showRowEdit: boolean;
    showSearchColumns: boolean;
    sort: Sort<SortAttribute> | null;
    totalCount: number;

    clearNameCursors(): void;
    getMassActionId(row: T): number;
    reload(): void;
    reloadRow(id: TId): void;
    renderActions?(
        massActions: IUseMassActionsResult<TId>,
        totalCount: number,
        reload: () => void
    ): React.ReactNode;
    setAreColumnsOpen(areColumnsOpen: boolean): void;
    setColumns(columns: Columns): void;
    setColumnsSearch(columns: Columns): void;
    setIsEditMode(isEditMode: boolean): void;
    setIsFilterOpen(isFilterOpen: boolean): void;
    setLimit(limit: number): void;
    setName(name: string): void;
    setNameDebounced(name: string): void;
    setOffset(offset: number): void;
    setPredicates(predicates: Predicates): void;
    setRendering(rendering: AllRendering): void;
    setSelectedFilterPredicate(selectedFilterPredicate: FilterPredicate | null): void;
    setSort(sort: Sort<SortAttribute> | null): void;
}

export const FiltersColumnsContext: React.Context<IFiltersColumnsContext<any, any, any>> =
    React.createContext({} as any);

export function useFiltersColumnsContext<
    T extends { id: TId },
    TId extends NominalType<number, any>,
    SortAttribute
>(): IFiltersColumnsContext<T, TId, SortAttribute> {
    return React.useContext(FiltersColumnsContext);
}
