import { UpdateCell } from 'common-front/src/components/cells/updateCell';
import { useEventsMassEditMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { EventId, EventProperty, MassAssignStrategy } from 'common/src/generated/types';
import * as React from 'react';

interface IEventsUpdateCellProps<T> {
    cellCss?: any;
    initialValue: T;
    eventId: EventId;
    property: EventProperty;
    updatable: boolean;

    reload(): void;
    renderInput(value: T, setValue: (v: T) => void): React.ReactNode;
    renderValue: string | (() => React.ReactNode);
}

export const EventsUpdateCell = <T extends {}>(props: IEventsUpdateCellProps<T>) => {
    const {
        params: { organizationId }
    } = useHeavent();
    const { mutate } = useEventsMassEditMutation();

    return (
        <UpdateCell
            cellCss={props.cellCss}
            initialValue={props.initialValue}
            mutate={(value) =>
                mutate({
                    organizationId,
                    massEdit: {
                        reset: false,
                        selecteds: { ids: [props.eventId] },
                        slug: props.property,
                        strategy: MassAssignStrategy.Replace,
                        value
                    }
                })
            }
            reload={props.reload}
            renderInput={props.renderInput}
            renderValue={props.renderValue}
            updatable={props.updatable}
        />
    );
};
