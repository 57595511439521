import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentsGrid } from 'common-front/src/segments/segmentsGrid';
import { SegmentId, SegmentType, UsersSegmentsQuery } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { CommunityPaths } from 'common/src/util/paths/communityPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import { UsersSegmentsService } from 'common/src/vo/segments/usersSegmentsService';
import * as React from 'react';
import { useOrganizationContext } from '../../organization/organizationContext';
import { CommunityUsersContent } from './communityUsersContent';

interface ICommunityUsersProps {
    organization: UsersSegmentsQuery['organization'];

    reload(): void;
}

export const CommunityUsers = (props: ICommunityUsersProps) => {
    const {
        history,
        translate,
        params: { organizationId, segmentId }
    } = useHeavent();
    const { localOrganization, updateLocalOrganization } = useOrganizationContext();
    const segmentService = useService(UsersSegmentsService);
    const filters = React.useMemo(
        () =>
            segmentService.getOrganizationUsersFilters(
                organizationId,
                segmentId,
                props.organization
            ),
        [props.organization]
    );
    const possibleColumns = React.useMemo(
        () =>
            segmentService.getOrganizationUsersPossibleColumns(
                props.organization.customFields.nodes
            ),
        [props.organization]
    );

    return (
        <SegmentsGrid
            filters={filters}
            filtersButtonText={translate('filtrer_les_mem_49255')}
            filtersSubtitle={translate('appliquer_des_f_48814')}
            getSegmentPath={(id: SegmentId) =>
                HeaventPaths.COMMUNITY_USERS_SEGMENT(organizationId, id)
            }
            initialLimit={localOrganization?.usersLimit ?? DEFAULT_PAGINATION_LIMIT}
            initialSegmentsOpen={localOrganization?.areUsersSegmentsOpen ?? true}
            isAdmin={true}
            organizationId={organizationId}
            possibleColumns={possibleColumns}
            possibleRenderings={[]}
            reload={props.reload}
            segmentId={segmentId}
            segmentType={SegmentType.Volunteers}
            segmentsFolders={props.organization.segmentsFolders}
            setLimit={(usersLimit) => {
                updateLocalOrganization({ usersLimit });
            }}
            onSegmentClick={(selectedSegmentId) => {
                updateLocalOrganization({ usersSegmentId: selectedSegmentId });
            }}
            onSegmentDelete={async () => {
                await updateLocalOrganization({ usersSegmentId: null });

                history.replace(CommunityPaths.COMMUNITY_USERS({ organizationId }));
            }}
            onSegmentsToggle={async (areSegmentsOpen) => {
                await updateLocalOrganization({ areUsersSegmentsOpen: areSegmentsOpen });
            }}
        >
            <CommunityUsersContent customFields={props.organization.customFields.nodes} />
        </SegmentsGrid>
    );
};
