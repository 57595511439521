import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { EventsEventFragment } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { startAtEndAtToString } from 'common/src/vo/event';
import * as React from 'react';

export const EventProperties = ({ event }: { event: EventsEventFragment }) => {
    const dateTimeService = useService(DateTimeService);

    return (
        <Box>
            <Flex align="center" css={{ color: '$gray500' }}>
                <Box width={25}>
                    <I icon="calendar" />
                </Box>

                <Box css={{ flex: '1', ellipsis: true }}>
                    {startAtEndAtToString(dateTimeService, event.startAt, event.endAt)}
                </Box>
            </Flex>
        </Box>
    );
};
