import { Emptyable } from '../emptyable';
import { buildPath, CommonPaths, commonSlugs, UserParams } from './commonPaths';
import { CommunityPaths } from './communityPaths';
import { EventParams, EventsPaths } from './eventsPaths';
import { OrganizationParams } from './organizationsPaths';
import {
    DelegationCategoryIdPathParam,
    DelegationIdPathParam,
    EventIdPathParam,
    FormIdPathParam
} from './pathsTypes';

const slugs = {
    ITEMS: {
        DELEGATIONS_CATEGORIES: {
            PLURAL: 'delegations-categories',
            SINGLE: 'delegation-category'
        },
        DELEGATIONS: {
            PLURAL: 'delegations',
            SINGLE: 'delegation'
        }
    },
    SECTIONS: {
        ACCREDITATIONS: 'accreditations',
        AVAILABILITIES: 'availabilities',
        POSITIONS: 'positions',
        REPORTING: 'reporting'
    }
} as const;

const itemPaths = {
    DELEGATIONS_CATEGORIES: slugs.ITEMS.DELEGATIONS_CATEGORIES.PLURAL,
    DELEGATION_CATEGORY(delegationCategoryId: DelegationCategoryIdPathParam) {
        return buildPath([slugs.ITEMS.DELEGATIONS_CATEGORIES.SINGLE, delegationCategoryId]);
    },

    DELEGATIONS: slugs.ITEMS.DELEGATIONS.PLURAL,
    DELEGATION: (delegationId: DelegationIdPathParam) =>
        buildPath([slugs.ITEMS.DELEGATIONS.SINGLE, delegationId])
};

export type DelegationCategoryParams = OrganizationParams & {
    eventId?: Emptyable<EventIdPathParam>;
    delegationCategoryId: DelegationCategoryIdPathParam;
};

export type DelegationParams = OrganizationParams &
    Partial<EventParams> & {
        delegationId: DelegationIdPathParam;
    };

export type DelegationUserParams = UserParams<DelegationParams>;

export type DelegationFormParams = DelegationParams & { formId: FormIdPathParam };

const delegationsCategoriesPaths = {
    DELEGATION_CATEGORY({
        organizationId,
        eventId,
        delegationCategoryId
    }: DelegationCategoryParams) {
        return buildPath([
            eventId
                ? EventsPaths.EVENT({ organizationId, eventId })
                : CommunityPaths.COMMUNITY({ organizationId }),
            itemPaths.DELEGATION_CATEGORY(delegationCategoryId)
        ]);
    },
    DELEGATION_CATEGORY_DASHBOARD(params: DelegationCategoryParams) {
        return buildPath([
            delegationsCategoriesPaths.DELEGATION_CATEGORY(params),
            commonSlugs.SECTIONS.DASHBOARD
        ]);
    },
    DELEGATION_CATEGORY_MEMBERS(params: DelegationCategoryParams) {
        return buildPath([
            delegationsCategoriesPaths.DELEGATION_CATEGORY(params),
            commonSlugs.SECTIONS.MEMBERS
        ]);
    }
};

const delegationsPaths = {
    //
    // CREATE
    //

    //
    // READ
    //

    DELEGATIONS: ({ eventId, ...params }: OrganizationParams & Partial<EventParams>) =>
        buildPath([
            eventId ? EventsPaths.EVENT({ ...params, eventId }) : CommunityPaths.COMMUNITY(params),
            itemPaths.DELEGATIONS
        ]),
    DELEGATION: ({ delegationId, eventId, ...params }: DelegationParams) =>
        buildPath([
            eventId ? EventsPaths.EVENT({ ...params, eventId }) : CommunityPaths.COMMUNITY(params),
            itemPaths.DELEGATION(delegationId)
        ]),

    // Backoffice space

    DELEGATION_DASHBOARD: (params: DelegationParams) =>
        buildPath([delegationsPaths.DELEGATION(params), commonSlugs.SECTIONS.DASHBOARD]),
    DELEGATION_MEMBERS: (params: DelegationParams) =>
        buildPath([delegationsPaths.DELEGATION(params), commonSlugs.SECTIONS.MEMBERS]),
    DELEGATION_ACCREDITATIONS: (params: DelegationParams) =>
        buildPath([delegationsPaths.DELEGATION(params), slugs.SECTIONS.ACCREDITATIONS]),
    DELEGATION_REPORTING: (params: DelegationParams) =>
        buildPath([delegationsPaths.DELEGATION(params), commonSlugs.SECTIONS.REPORTING]),
    DELEGATION_DOCUMENTS: (params: DelegationParams) =>
        buildPath([delegationsPaths.DELEGATION(params), commonSlugs.SECTIONS.DOCUMENTS]),

    // Member overlay

    DELEGATION_MEMBER: ({ userInfoId, ...params }: UserParams<DelegationParams>) =>
        buildPath([
            delegationsPaths.DELEGATION_MEMBERS(params),
            commonSlugs.SECTIONS.MEMBER,
            userInfoId
        ]),
    DELEGATION_MEMBER_INFORMATION: (params: UserParams<DelegationParams>) =>
        buildPath([delegationsPaths.DELEGATION_MEMBER(params), commonSlugs.SECTIONS.INFORMATION]),
    DELEGATION_MEMBER_ACCREDITATIONS: (params: DelegationUserParams) =>
        buildPath([delegationsPaths.DELEGATION_MEMBER(params), slugs.SECTIONS.ACCREDITATIONS]),
    DELEGATION_MEMBER_AVAILABILITIES: (params: DelegationUserParams) =>
        buildPath([delegationsPaths.DELEGATION_MEMBER(params), slugs.SECTIONS.AVAILABILITIES]),
    DELEGATION_MEMBER_POSITIONS: (params: DelegationUserParams) =>
        buildPath([delegationsPaths.DELEGATION_MEMBER(params), slugs.SECTIONS.POSITIONS]),

    // Delegation space

    MEMBER_CREATE: ({ formId, ...params }: DelegationFormParams) =>
        CommonPaths.CREATE(
            buildPath([
                delegationsPaths.DELEGATION(params),
                commonSlugs.SECTIONS.MEMBER,
                commonSlugs.SECTIONS.FORM,
                formId
            ])
        ),
    MEMBER_EDIT: ({ formId, ...params }: DelegationFormParams & DelegationUserParams) =>
        CommonPaths.EDIT(
            buildPath([
                delegationsPaths.DELEGATION_MEMBER(params),
                commonSlugs.SECTIONS.FORM,
                formId
            ])
        ),
    DELEGATION_PROFILE: (params: DelegationParams) =>
        buildPath([delegationsPaths.DELEGATION(params), commonSlugs.SECTIONS.PROFILE]),
    FIELDS_EDIT: (params: DelegationParams) =>
        CommonPaths.EDIT(
            buildPath([delegationsPaths.DELEGATION(params), commonSlugs.ITEMS.FIELDS.PLURAL])
        )

    //
    // UPDATE
    //

    //
    // DELETE
    //
};

export const DelegationsPaths = {
    ...delegationsCategoriesPaths,
    ...delegationsPaths
};
