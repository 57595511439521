import { Box } from 'common/src/designSystem/components/box';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FieldProperty,
    FormElementDisplayFragment,
    FormElementType,
    OrganizationId
} from 'common/src/generated/types';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import { Emptyable } from 'common/src/util/emptyable';
import { Fields, shouldDisplay } from 'common/src/vo/field';
import * as React from 'react';
import { AddressPlaceChange } from './addressPlaceChange';
import { UserCreateField } from './userCreateField';

interface IUserFormProps {
    countryCode?: Emptyable<string>;
    elements: FormElementDisplayFragment[];
    organizationId: OrganizationId;
    prefix: string;
    values: Fields;

    change(name: string, value: any): void;
    renderSection?(section: string, index: number): React.ReactNode;
    renderText?(text: string): string;
}

export const UserCreateForm = (props: IUserFormProps) => {
    const customFields = React.useMemo(
        () =>
            props.elements.flatMap((element) => {
                if (element.elementType === FormElementType.Field) {
                    return [element.customField!];
                } else {
                    return [];
                }
            }),
        [props.elements]
    );

    return (
        <Box>
            {props.elements.map((element, index) => {
                switch (element.elementType) {
                    case FormElementType.Field: {
                        const field = element.customField!;
                        const display = shouldDisplay(field, props.values, customFields);

                        return (
                            <React.Fragment key={index}>
                                {field.fieldProperty === FieldProperty.Street && (
                                    <AddressPlaceChange
                                        change={props.change}
                                        changeCity={true}
                                        changeCountry={true}
                                        changePostalCode={true}
                                        name="place"
                                        prefix={props.prefix}
                                    />
                                )}

                                {display && index !== 0 && <Spacer height="4" />}

                                <UserCreateField
                                    key={index}
                                    change={props.change}
                                    countryCode={props.countryCode}
                                    display={display}
                                    field={field}
                                    isMandatory={element.isMandatory === true}
                                    organizationId={props.organizationId}
                                    prefix={props.prefix}
                                />
                            </React.Fragment>
                        );
                    }
                    case FormElementType.Section:
                        return (
                            <React.Fragment key={index}>
                                {typeof props.renderSection === 'function' ? (
                                    props.renderSection(element.section!, index)
                                ) : (
                                    <>
                                        {index !== 0 && <Spacer height="8" />}

                                        <Box font="gray800 textMd semiBold">
                                            {typeof props.renderText === 'function'
                                                ? props.renderText(element.section!)
                                                : element.section}
                                        </Box>
                                    </>
                                )}
                            </React.Fragment>
                        );
                    case FormElementType.Text:
                        return (
                            <React.Fragment key={index}>
                                <>
                                    {index !== 0 && <Spacer height="4" />}

                                    <RichText
                                        text={
                                            typeof props.renderText === 'function'
                                                ? props.renderText(element.text!)
                                                : element.text!
                                        }
                                    />
                                </>
                            </React.Fragment>
                        );
                    default:
                        return assertUnreachable(element.elementType);
                }
            })}
        </Box>
    );
};
