import { DateInput } from 'common-front/src/designSystem/components/date/dateInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { EventProperty, EventsEventFragment } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import * as React from 'react';
import { EventsUpdateCell } from './eventsUpdateCell';

interface IEventsEndAtCellProps {
    event: EventsEventFragment;

    reload(): void;
}

export const EventsEndAtCell = (props: IEventsEndAtCellProps) => {
    const { translate } = useHeavent();
    const dateTimeService = useService(DateTimeService);

    return (
        <EventsUpdateCell
            eventId={props.event.id}
            initialValue={props.event.endAt}
            property={EventProperty.EndAt}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <DateInput
                    label={translate('date_de_fin_86920')}
                    value={value}
                    onChange={setValue}
                />
            )}
            renderValue={dateTimeService.toLocaleString(
                props.event.endAt,
                LocaleFormats.DateOnly.WeekdayLongMonthLong
            )}
            updatable={true}
        />
    );
};
