import { ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSlugToCustomField } from 'common-front/src/hooks/useSlugToCustomField';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    EventId,
    EventsEventFragment,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { EventCells } from './eventCells';
import { EventRowDropdown } from './eventRowDropdown';

interface IEventRowProps {
    columns: PossibleColumn[];
    customFields: SegmentCustomFieldFragment[];
    event: EventsEventFragment;
    state: ICheckboxState;

    reload(): void;
    reloadEvent(id: EventId): void;
    toggleRow(key: string, newState: ICheckboxState): void;
}

export const EventRow = (props: IEventRowProps) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const slugToCustomField = useSlugToCustomField(props.customFields);
    const reloadEvent = React.useCallback(() => {
        props.reloadEvent(props.event.id);
    }, [props.event, props.reloadEvent]);

    return (
        <Row
            css={{
                background: props.state === 'checked' ? '$primary100' : 'white',
                '&:hover': {
                    background: props.state === 'checked' ? '$primary100' : '$gray100'
                }
            }}
            onMouseLeave={(e) => {
                closeOtherDropdowns(e.target);
            }}
        >
            {props.columns.map((field) => {
                const customField = slugToCustomField.get(field.slug);

                return (
                    <EventCells
                        key={field.slug}
                        column={field}
                        customField={customField}
                        organizationId={organizationId}
                        reloadRow={reloadEvent}
                        row={props.event}
                    />
                );
            })}

            <CellControls justify="center">
                <Tooltip>
                    <Trigger>
                        <Box>
                            <I
                                icon="pen"
                                to={HeaventPaths.EDIT_EVENT(organizationId, props.event.id)}
                            />
                        </Box>
                    </Trigger>

                    <Content placement="top">{translate('_diter_62574')}</Content>
                </Tooltip>

                <EventRowDropdown event={props.event} reload={props.reload} />
            </CellControls>
        </Row>
    );
};
