import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { TableGrid } from 'common/src/designSystem/components/table/tableGrid';
import {
    EventId,
    EventsEventFragment,
    EventsSortAttributes,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import { EventDefaultColumns } from 'common/src/vo/segments/eventsSegmentsService';
import { compact } from 'lodash-es';
import * as React from 'react';
import { executeEventsEventQuery, useEventsQuery } from '../../../generated/graphqlHooks';
import { EventRow } from './eventRow';

interface IEventsListProps {
    customFields: SegmentCustomFieldFragment[];
}

export const EventsList = (props: IEventsListProps) => {
    const {
        params: { organizationId }
    } = useHeavent();
    const {
        columns,
        possibleColumns,
        nameDebounced,
        predicates,
        limit,
        offset,
        sort,
        massActions,
        setLimit,
        setOffset,
        setSort
    } = useSegmentsContext<EventId, EventsSortAttributes>();
    const { data, isLoading, reload } = useEventsQuery({
        organizationId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : null,
        predicates,
        limit,
        offset,
        sort
    });
    const selectedFields = React.useMemo(
        () => columns.flatMap((slug) => compact([possibleColumns.find((pc) => pc.slug === slug)])),
        [possibleColumns, columns]
    );
    const { numberOfPages, totalCount } = usePaginationInfos(data.data?.rows);
    const [events, setEvents] = React.useState<EventsEventFragment[]>([]);
    const [idToLoading, setIdToLoading] = React.useState<Record<EventId, boolean>>({});
    const reloadEvent = React.useCallback(
        async (id: EventId) => {
            setIdToLoading((ids) => ({ ...ids, [id]: true }));

            const {
                data: { row }
            } = await executeEventsEventQuery({ organizationId, id }, await getToken());

            setEvents((es) => es.map((e) => (e.id === id ? row : e)));
            setIdToLoading((ids) => ({ ...ids, [id]: false }));
        },
        [setIdToLoading, setEvents]
    );

    React.useEffect(() => {
        setEvents(data.data?.rows.nodes ?? []);
    }, [data.data]);

    React.useEffect(() => {
        massActions.setStates(
            Object.fromEntries(
                (data.data?.rows.nodes ?? []).map((event) => [
                    `e${event.id}`,
                    { id: event.id, state: 'unchecked' }
                ])
            )
        );
    }, [data.data, massActions.setStates]);

    return (
        <Flex css={{ flex: '1', overflow: 'hidden' }} width={1}>
            <TableGrid
                headerRow={
                    <HeaderRow>
                        {selectedFields.map((field) =>
                            field.slug === EventDefaultColumns.Id ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={EventsSortAttributes.Id}
                                    setSort={setSort}
                                    sort={sort}
                                    width={100}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === EventDefaultColumns.Name ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={EventsSortAttributes.Name}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === EventDefaultColumns.StartAt ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={EventsSortAttributes.StartAt}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === EventDefaultColumns.EndAt ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={EventsSortAttributes.EndAt}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : (
                                <HeaderCell key={field.slug}>
                                    <Box css={{ ellipsis: true }} title={field.name}>
                                        {field.name}
                                    </Box>
                                </HeaderCell>
                            )
                        )}

                        <HeaderCell width={100} />
                    </HeaderRow>
                }
                paginationRow={
                    <PaginationRow css={{ borderTop: '1px solid $gray200' }}>
                        <PaginationCell
                            limit={limit}
                            numberOfPages={numberOfPages}
                            offset={offset}
                            setLimit={setLimit}
                            setOffset={setOffset}
                            showLimits={true}
                            totalCount={totalCount}
                        />
                    </PaginationRow>
                }
            >
                {isLoading ? (
                    <>
                        <RowSkeleton />
                        <RowSkeleton />
                        <RowSkeleton />
                    </>
                ) : (
                    events.map((event) => {
                        if (idToLoading[event.id]) {
                            return <RowSkeleton key={event.id} />;
                        } else {
                            return (
                                <EventRow
                                    key={event.id}
                                    columns={selectedFields}
                                    customFields={props.customFields}
                                    event={event}
                                    reload={reload}
                                    reloadEvent={reloadEvent}
                                    state={massActions.states[`e${event.id}`]?.state ?? 'unchecked'}
                                    toggleRow={massActions.toggleRow}
                                />
                            );
                        }
                    })
                )}
            </TableGrid>
        </Flex>
    );
};
