import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { TableGrid } from 'common/src/designSystem/components/table/tableGrid';
import { NominalType } from 'common/src/util/nominalType';
import { PossibleColumn } from 'common/src/vo/segment';
import { compact } from 'lodash-es';
import * as React from 'react';
import { Checkbox } from '../designSystem/components/checkbox';
import { PaginationCell } from '../designSystem/components/pagination/paginationCell';
import { PaginationRow } from '../designSystem/components/pagination/paginationRow';
import { useFiltersColumnsContext } from '../filtersColumns/filtersColumnsContext';
import { RichTableRow } from './richTableRow';

interface IRichTableListProps<T extends { id: TId }, TId extends NominalType<number, any>> {
    getEditPath?(row: T): string;
    renderCell(column: PossibleColumn, row: T, reload: () => void): React.ReactNode;
    renderRowDropdown?(row: T, reload: () => void): React.ReactNode;
    renderRowPanels?(row: T, reload: () => void): React.ReactNode;
}

export const RichTableList = <T extends { id: TId }, TId extends NominalType<number, any>>(
    props: IRichTableListProps<T, TId>
) => {
    const {
        columns,
        data,
        idToLoading,
        isLoading,
        limit,
        massActions,
        numberOfPages,
        offset,
        possibleColumns,
        setLimit,
        setOffset,
        setSort,
        showMassActions,
        showRowDropdown,
        showRowEdit,
        sort,
        totalCount
    } = useFiltersColumnsContext();
    const selectedColumns = React.useMemo(
        () => columns.flatMap((slug) => compact([possibleColumns.find((pc) => pc.slug === slug)])),
        [columns, possibleColumns]
    );

    return (
        <Flex css={{ flex: '1', overflow: 'hidden' }} width={1}>
            <TableGrid
                headerRow={
                    <HeaderRow>
                        {showMassActions && (
                            <HeaderCell justify="center" width={48}>
                                <Checkbox
                                    state={massActions.selectAllState}
                                    onClick={massActions.toggleSelectAll}
                                />
                            </HeaderCell>
                        )}

                        {selectedColumns.map((field) =>
                            field.isSortable ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={field.sortAttribute as any}
                                    setSort={setSort}
                                    sort={sort as any}
                                    width={field.width}
                                >
                                    <Box css={{ ellipsis: true }} title={field.name}>
                                        {field.name}
                                    </Box>
                                </HeaderCellSort>
                            ) : (
                                <HeaderCell key={field.slug} width={field.width}>
                                    <Box css={{ ellipsis: true }} title={field.name}>
                                        {field.name}
                                    </Box>
                                </HeaderCell>
                            )
                        )}

                        {(showRowDropdown || showRowEdit) && <HeaderCell width={100} />}
                    </HeaderRow>
                }
                paginationRow={
                    <PaginationRow css={{ borderTop: '1px solid $gray200' }}>
                        <PaginationCell
                            limit={limit}
                            numberOfPages={numberOfPages}
                            offset={offset}
                            setLimit={setLimit}
                            setOffset={setOffset}
                            showLimits={true}
                            totalCount={totalCount}
                        />
                    </PaginationRow>
                }
            >
                {isLoading ? (
                    <>
                        <RowSkeleton />
                        <RowSkeleton />
                        <RowSkeleton />
                    </>
                ) : (
                    data.map((row) => {
                        if (idToLoading[row.id]) {
                            return <RowSkeleton key={row.id} />;
                        } else {
                            return (
                                <RichTableRow
                                    key={row.id as any}
                                    getEditPath={props.getEditPath}
                                    renderCell={props.renderCell}
                                    renderRowDropdown={props.renderRowDropdown}
                                    renderRowPanels={props.renderRowPanels}
                                    row={row}
                                    selectedColumns={selectedColumns}
                                />
                            );
                        }
                    })
                )}
            </TableGrid>
        </Flex>
    );
};
