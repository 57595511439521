import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger as TooltipTrigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentHeader } from 'common-front/src/segments/segmentHeader';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Feature, PositionsSegmentsQuery } from 'common/src/generated/types';
import { PositionPermission } from 'common/src/graphql/permissions/dsl';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { getDocumentHref } from 'common/src/util/url';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { usePositionsHeaderQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { CreatePositionCategory } from '../../positionsCategories/create/createUpdatePositionCategory';
import { KmlImport } from '../import/kml/kmlImport';
import { usePositionsContext } from './positionsContext';

interface IPositionsHeaderProps {
    name: string;
    numberOfCategories: number;
    segment: PositionsSegmentsQuery['event']['segmentsFolders'][0]['segments'][0];
}

export const PositionsHeader = ({ name, numberOfCategories, segment }: IPositionsHeaderProps) => {
    const {
        history,
        params: { organizationId, eventId },
        translate
    } = useHeavent();
    const { hasFeature } = useOrganizationContext();
    const { isEventAdmin, hasPositionPermissions } = useEventContext();
    const { newPositionLatLng } = usePositionsContext();
    const { data } = usePositionsHeaderQuery({
        eventId,
        segmentId: segment.id
    });
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [isCreatePositionCategoryOpen, setIsCreatePositionCategoryOpen] = React.useState(false);
    const [isKmlImportOpen, setIsKmlImportOpen] = React.useState(false);
    const [canEditCategory] = hasPositionPermissions([PositionPermission.EDIT_POSITION], {
        positionCategoryId: 'ANY'
    });

    return (
        <SegmentHeader
            name={
                <Flex
                    align="center"
                    css={{ flex: '1', overflow: 'hidden', position: 'relative' }}
                    gap="2"
                >
                    <Box css={{ ellipsis: true }} font="gray900 displaySm semiBold" title={name}>
                        {name}
                    </Box>

                    <Tooltip>
                        <TooltipTrigger>
                            <Box color="gray500">
                                <I icon="circle-exclamation" />
                            </Box>
                        </TooltipTrigger>

                        <Content placement="bottom-start" renderOnPortal={true}>
                            {translate('nombre_de_missi_06171', data.event?.segment.count ?? 0)}
                            <br />
                            {translate(
                                'nombre_de_membr_16367',
                                data.event?.segment.stats?.assignedResources ?? 0
                            )}
                            <br />
                            {translate(
                                'nombre_de_membr_29724',
                                data.event?.segment.stats?.resources ?? 0
                            )}
                        </Content>
                    </Tooltip>
                </Flex>
            }
        >
            {canEditCategory && (
                <Button
                    onClick={() => {
                        let positionParam: string | undefined;

                        if (newPositionLatLng) {
                            positionParam = `${newPositionLatLng.latitude},${newPositionLatLng.longitude}`;
                        }

                        history.push(
                            HeaventPaths.CREATE_POSITION(organizationId, eventId, positionParam)
                        );
                    }}
                >
                    {translate('cr_er_une_missi_66818')}
                </Button>
            )}

            {isEventAdmin && (
                <>
                    <Dropdown isOpen={isDropdownOpen} onStateChange={setIsDropdownOpen}>
                        <Trigger>
                            <Button color="white" leftIcon="ellipsis-vertical" />
                        </Trigger>

                        <Menu placement="bottom-end">
                            <ItemIcon
                                icon="plus"
                                onClick={() => {
                                    setIsDropdownOpen(false);
                                    setIsCreatePositionCategoryOpen(true);
                                }}
                            >
                                {translate('cr_er_une_cat_g_20051')}
                            </ItemIcon>

                            <ItemIcon
                                icon="calendar"
                                to={getDocumentHref(
                                    data.event?.segment.links.positionsPlanningLink,
                                    'html'
                                )}
                            >
                                {translate('voir_le_plannin_85187')}
                            </ItemIcon>

                            <ItemIcon
                                icon="calendar"
                                to={getDocumentHref(
                                    data.event?.segment.links.positionsVolunteersPlanningLink,
                                    'html'
                                )}
                            >
                                {translate('voir_le_plannin_47650')}
                            </ItemIcon>

                            <ItemIcon
                                download={`${segment.name}-positions-planning`}
                                icon="arrow-down-to-bracket"
                                to={getDocumentHref(
                                    data.event?.segment.links.positionsPlanningLink,
                                    'pdf'
                                )}
                            >
                                {translate('t_l_charger_le_06050')}
                            </ItemIcon>

                            <ItemIcon
                                download={`${segment.name}-positions-volunteers-planning`}
                                icon="arrow-down-to-bracket"
                                to={getDocumentHref(
                                    data.event?.segment.links.positionsVolunteersPlanningLink,
                                    'pdf'
                                )}
                            >
                                {translate('t_l_charger_le_02952')}
                            </ItemIcon>

                            {hasFeature(Feature.KmlImport) && (
                                <ItemIcon
                                    color={numberOfCategories === 0 ? 'disabled' : 'default'}
                                    icon="file-import"
                                    onClick={() => {
                                        setIsDropdownOpen(false);
                                        setIsKmlImportOpen(true);
                                    }}
                                >
                                    {translate('importer_des_mi_32264')}
                                </ItemIcon>
                            )}

                            <ItemIcon
                                icon="file-import"
                                to={PositionsPaths.POSITION_IMPORT_CSV({
                                    organizationId,
                                    eventId
                                })}
                            >
                                {translate('import_tasks_fr_52548')}
                            </ItemIcon>
                        </Menu>
                    </Dropdown>

                    {isCreatePositionCategoryOpen && (
                        <CreatePositionCategory
                            eventId={eventId}
                            onClose={() => {
                                setIsCreatePositionCategoryOpen(false);
                            }}
                            onSuccess={({ id }) => {
                                history.push(
                                    PositionsPaths.POSITION_CATEGORY({
                                        organizationId,
                                        eventId,
                                        positionCategoryId: id
                                    })
                                );
                            }}
                        />
                    )}

                    {isKmlImportOpen && (
                        <KmlImport
                            eventId={eventId}
                            onClose={() => {
                                setIsKmlImportOpen(false);
                            }}
                        />
                    )}
                </>
            )}
        </SegmentHeader>
    );
};
