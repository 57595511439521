import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { Filter, FilterPredicate, partitionFiltersPredicates } from 'common/src/vo/segment';
import { Predicate, Predicates } from 'common/src/vo/segments/predicate';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { RightPanel } from '../../designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from '../../designSystem/components/rightPanel/rightPanelBody';
import { RightPanelFooter } from '../../designSystem/components/rightPanel/rightPanelFooter';
import { TextInput } from '../../designSystem/components/textInput';
import { AddFilter } from './addFilter';
import { FiltersList } from './filtersList';

interface IFiltersProps {
    buttonText?: string;
    possibleFilters: Filter[];
    predicates: Predicates;
    selectedFilterPredicate: FilterPredicate | null;
    subtitle?: string;

    onClose(): void;
    onFilter(predicates: Predicates): void;
}

export const Filters = (props: IFiltersProps) => {
    const translate = useTranslate();
    const [selectedFilterPredicate, setSelectedFilterPredicate] =
        React.useState<FilterPredicate | null>(props.selectedFilterPredicate);
    const [predicates, setPredicates] = React.useState<Predicates>(cloneDeep(props.predicates));
    const onAdd = React.useCallback(
        (predicate: Predicate) => {
            setPredicates(
                produce((draft: Predicates) => {
                    const currentIndex = draft.findIndex((p) => p.slug === predicate.slug);

                    if (currentIndex === -1) {
                        draft.push(predicate);
                    } else {
                        draft[currentIndex] = predicate;
                    }
                })
            );

            setSelectedFilterPredicate(null);
        },
        [setPredicates, setSelectedFilterPredicate]
    );
    const onDelete = React.useCallback(
        (slug: string) => {
            setPredicates(
                produce((draft: Predicates) => {
                    const currentIndex = draft.findIndex((p) => p.slug === slug);

                    draft.splice(currentIndex, 1);
                })
            );
        },
        [setPredicates]
    );
    const [search, setSearch] = React.useState('');
    const [selected, others] = partitionFiltersPredicates(
        predicates,
        props.possibleFilters,
        search
    );

    return (
        <RightPanel size="sm" onClose={props.onClose}>
            <RightPanelBody>
                <Spacer height="6" />

                <Box color="gray900" fontSize="textXl">
                    {translate('filtres_64574')}
                </Box>

                {isNonEmptyString(props.subtitle) && <Box color="gray500">{props.subtitle}</Box>}

                <Spacer height="6" />

                {selectedFilterPredicate ? (
                    <AddFilter
                        filterPredicate={selectedFilterPredicate}
                        onAdd={onAdd}
                        onBack={() => {
                            setSelectedFilterPredicate(null);
                        }}
                    />
                ) : (
                    <>
                        <TextInput
                            placeholder={translate('rechercher_un_f_22061')}
                            value={search}
                            onChange={setSearch}
                        />

                        <Spacer height="6" />

                        {predicates.length > 0 && selected.length > 0 && (
                            <>
                                <Box color="gray900" fontSize="textMd" fontWeight="semiBold">
                                    {translate('liste_des_filtr_29511')}
                                </Box>

                                <Spacer height="2" />

                                <FiltersList
                                    filtersPredicates={selected}
                                    isEdit={true}
                                    onDelete={onDelete}
                                    onEdit={(filterPredicate) => {
                                        setSelectedFilterPredicate(filterPredicate);
                                    }}
                                />

                                <Spacer height="8" />
                            </>
                        )}

                        <Box color="gray900" fontSize="textMd" fontWeight="semiBold">
                            {translate('liste_des_filtr_37728')}
                        </Box>

                        {others.length > 0 && (
                            <>
                                <Spacer height="2" />

                                <FiltersList
                                    filtersPredicates={others}
                                    isEdit={false}
                                    onAdd={(filterPredicate) => {
                                        setSelectedFilterPredicate(filterPredicate);
                                    }}
                                />
                            </>
                        )}
                    </>
                )}

                <Spacer height="6" />
            </RightPanelBody>

            {selectedFilterPredicate === null && (
                <RightPanelFooter>
                    <Flex gap="3" justify="end">
                        <Button
                            onClick={() => {
                                props.onFilter(predicates);
                                props.onClose();
                            }}
                        >
                            {props.buttonText || translate('filtrer_78031')}
                        </Button>

                        <Button color="white" onClick={props.onClose}>
                            {translate('annuler_48254')}
                        </Button>
                    </Flex>
                </RightPanelFooter>
            )}
        </RightPanel>
    );
};
