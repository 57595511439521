import { Blank } from 'common-front/src/components/blank/blank';
import { Page } from 'common-front/src/components/page/page';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SelectSegment } from 'common-front/src/segments/massActions/selectSegment';
import { SearchRow } from 'common-front/src/segments/searchRow';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { SegmentTableHeader } from 'common-front/src/segments/segmentTableHeader';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { OrganizationEventLevel, SegmentCustomFieldFragment } from 'common/src/generated/types';
import * as React from 'react';
import { DelegationsActions } from './delegationsActions';
import { DelegationsHeader } from './delegationsHeader';
import { DelegationsList } from './delegationsList';

interface IDelegationsContentProps {
    customFields: SegmentCustomFieldFragment[];
    numberOfDelegations: number;
}

export const DelegationsContent = (props: IDelegationsContentProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { limit, segment } = useSegmentsContext();

    return (
        <>
            <DelegationsHeader />

            {props.numberOfDelegations === 0 ? (
                <Page.Content css={{ overflow: 'hidden' }}>
                    <Box css={{ overflow: 'hidden' }} width={1}>
                        <Blank
                            imageSrc={Svgs.AccreditationsBlank}
                            title={translate('vous_n_avez_pas_58215')}
                        />
                    </Box>
                </Page.Content>
            ) : (
                <Page.Content>
                    <SegmentTableHeader
                        searchRow={
                            <SearchRow
                                actions={<DelegationsActions />}
                                searchPlaceholder={translate('rechercher_une_31213')}
                                showEditColumns={true}
                                showEditMode={true}
                                showSearchColumns={true}
                            />
                        }
                        selectSegment={
                            <SelectSegment
                                pageSelectedText={translate('les_1_d_l_gat_27248', limit)}
                                segmentSelectedText={translate(
                                    'les_1_d_l_gat_04594',
                                    segment.count,
                                    segment.name
                                )}
                            />
                        }
                    />

                    <DelegationsList
                        customFields={props.customFields}
                        eventId={eventId}
                        level={OrganizationEventLevel.Event}
                        organizationId={organizationId}
                    />
                </Page.Content>
            )}
        </>
    );
};
