import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentsGrid } from 'common-front/src/segments/segmentsGrid';
import { EventsSegmentsQuery, SegmentId, SegmentType } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { EventsPaths } from 'common/src/util/paths/eventsPaths';
import { HeaventPaths } from 'common/src/util/paths/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import { EventsRendering } from 'common/src/vo/rendering';
import { EventsSegmentsService } from 'common/src/vo/segments/eventsSegmentsService';
import * as React from 'react';
import { useOrganizationContext } from '../../organization/organizationContext';
import { EventsContent } from './eventsContent';
import { EventsContextProvider } from './eventsContext';

interface IEventsProps {
    organization: EventsSegmentsQuery['organization'];

    reload(): void;
}

export const Events = (props: IEventsProps) => {
    const {
        history,
        translate,
        params: { organizationId, segmentId }
    } = useHeavent();
    const { localOrganization, updateLocalOrganization } = useOrganizationContext();
    const segmentService = useService(EventsSegmentsService);
    const customFields = React.useMemo(
        () => props.organization.customFields.nodes,
        [props.organization]
    );
    const filters = React.useMemo(
        () => segmentService.getEventsFilters(organizationId, customFields),
        [organizationId, customFields]
    );
    const possibleColumns = React.useMemo(
        () => segmentService.getEventPossibleColumns(customFields),
        [customFields]
    );

    return (
        <EventsContextProvider organizationId={organizationId}>
            <SegmentsGrid
                filters={filters}
                filtersButtonText={translate('filtrer_les_v_48869')}
                filtersSubtitle={translate('appliquer_des_f_84816')}
                getSegmentPath={(id: SegmentId) => HeaventPaths.EVENTS_SEGMENT(organizationId, id)}
                initialLimit={localOrganization?.eventsLimit ?? DEFAULT_PAGINATION_LIMIT}
                initialSegmentsOpen={localOrganization?.areEventsSegmentsOpen ?? true}
                isAdmin={true}
                organizationId={organizationId}
                possibleColumns={possibleColumns}
                possibleRenderings={[EventsRendering.Card, EventsRendering.List]}
                reload={props.reload}
                segmentId={segmentId}
                segmentType={SegmentType.Events}
                segmentsFolders={props.organization.segmentsFolders}
                setLimit={(eventsLimit) => {
                    updateLocalOrganization({ eventsLimit });
                }}
                onSegmentClick={(selectedSegmentId) => {
                    updateLocalOrganization({ eventsSegmentId: selectedSegmentId });
                }}
                onSegmentDelete={async () => {
                    await updateLocalOrganization({ eventsSegmentId: null });

                    history.replace(EventsPaths.EVENTS({ organizationId }));
                }}
                onSegmentsToggle={async (areSegmentsOpen) => {
                    await updateLocalOrganization({ areEventsSegmentsOpen: areSegmentsOpen });
                }}
            >
                <EventsContent customFields={customFields} />
            </SegmentsGrid>
        </EventsContextProvider>
    );
};
